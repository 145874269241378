/**
 *	@copyright	KARD.
 */

import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

import { connect } from "react-redux";
import * as actions from "rdx/actions";

import "./style.css";

/**
 */
const CarouselSection = (props) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  const [isBusy, IsBusy] = React.useState(false);
  const [list, List] = React.useState([]);
  const [lastKey, LastKey] = React.useState(null);

  React.useEffect(() => {
    Load().catch((err) => {});
  }, []);

  /**
   */
  const Load = async () => {
    try {
      IsBusy(true);

      const resp_list = await props.Crowd_List({ last_key: lastKey });

      console.log("crowd.CarouselSection: Load: resp_list: ", resp_list);

      List(resp_list.Items);
      LastKey(resp_list.LastEvaluatedKey);

      IsBusy(false);

      return {};
    } catch (err) {
      console.warn("crowd.CarouselSection: Load: err: ", err);

      IsBusy(false);

      return Promise.reject(err);
    }
  }; // Load

  /**
   */
  const onExiting = () => {
    setAnimating(true);
  };

  /**
   */
  const onExited = () => {
    setAnimating(false);
  };

  /**
   */
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === demo_items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  /**
   */
  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? demo_items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  /**
   */
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  /**
   */
  return (
    <>
      <Container fluid className="p-0 littlehope-carousel">
        <Row className="justify-content-center no-gutter">
          <Col lg="12" md="12">
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
              {demo_items.map((item) => {
                return (
                  <CarouselItem
                    onExiting={onExiting}
                    onExited={onExited}
                    key={item.src}
                  >
                    <img src={item.src} alt={item.altText} />
                    <div className="carousel-caption d-none d-md-block">
                      <h5 className="title text-white p-0 mt-3">
                        {item.caption}
                      </h5>
                      <p className="message">"{item.messsaage}"</p>
                    </div>
                  </CarouselItem>
                );
              })}
              {/* </> */}
              <a
                className="carousel-control-prev"
                data-slide="prev"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  previous();
                }}
                role="button"
              >
                <i className="now-ui-icons arrows-1_minimal-left"></i>
              </a>
              <a
                className="carousel-control-next"
                data-slide="next"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  next();
                }}
                role="button"
              >
                <i className="now-ui-icons arrows-1_minimal-right"></i>
              </a>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </>
  );
}; // CarouselSection

/**
 */
// export default CarouselSection;
/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(CarouselSection);

/**
 */
const demo_items = [
  {
    src: require("assets/img/slider/masjid.jpg").default,
    altText: "Nature, Kenya",
    caption: "Building a Masjid",
    description: "Rita made 76K to help traise these children",
    messsaage:
      "The Messenger of Allah (saw) said,“Whoever builds a mosque, desiring thereby Allah’s pleasure, Allah builds for him the like of it in paradise.’ [Bukhari]",
  },
  {
    src: require("assets/img/slider/slider-2.png").default,
    altText: "Somewhere Beyond, Uganda",
    caption: "Water Well",
    description: "Sabita made 76K to help traise these children",
    messsaage:
      "‘The Messenger of Allah (saw) said, “Whoever digs a well will receive reward from Allah on the Day of Judgement when anyone from amongst the jinn, men and birds drink from it.’ [Bukhari and Muslim]",
  },
  {
    src: require("assets/img/slider/dental.jpeg").default,
    altText: "Yellowstone National Park, Nigeria",
    caption: "Dental Clinic",
    description: "Mita made 76K to help traise these children",
    messsaage:
      "The Messenger of Allah (saw) said, “Allah (saw) didn’t send down an illness except that He sent down a cure.”’ [Bukhari]",
  },
  {
    src: require("assets/img/slider/eye.jpg").default,
    altText: "Yellowstone National Park, Nigeria",
    caption: "Eye Camp in Bangladesh",
    description: "Mita made 76K to help traise these children",
    messsaage:
      "You were heedless of this. Now We have removed your veil and so your vision today is sharp.’ [Surah Qaf 50:22]",
  },
  {
    src: require("assets/img/slider/tubewell.jpg").default,
    altText: "Yellowstone National Park, Nigeria",
    caption: "Tubewell",
    description: "Mita made 76K to help traise these children",
    messsaage:
      "‘The Messenger of Allah (saw) was asked, “Which charity is best?” He replied, “[Providing] water”.’ [Abu Dawud]",
  },
];
