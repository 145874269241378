/**
 *	@copyright	KARD.
 */

import * as AWS from '../../api/AWS'
import {Core, Lambda} from '../../api/AWS'

import * as Cfg from './cfg'
import {uploadFile} from "./storage";

/**
 */
export const CrowdCampaign_Create = () =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )

            const cur_usr = await aws_core.currentuser()

            const lambda_name = 'loggedin' === gs().__auth.status ? d( Cfg.Lambda('app') ) : d( Cfg.Lambda('unauth') )

            // console.log( 'actions/crowd: Crowd_List: lambda_name: ', lambda_name )

            const p_create = {
                actType: 'crowd',
                act: 'crowd:new',
                c_id: gs().__cfg.oId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    basic: {
                        ...gs().__ccamp.basic
                    },
                    project: {
                        ...gs().__ccamp.project,
                    },
                    funding: {
                        ...gs().__ccamp.funding,
                        target: parseInt( parseFloat( gs().__ccamp.funding.target ) * 100, 10 ),
                    },
                    content: {
                        ...gs().__ccamp.content
                        // listDesc: ...
                    },
                },
            }

            // console.log( 'actions.CrowdCampaign_Create: p_create: ', p_create )

            const resp_create = await aws_lambda.Invoke( p_create, lambda_name )

            // console.log( 'actions.CrowdCampaign_Create: resp_create: ', resp_create )

            return {resp_create}
        }
        catch( err )
        {
            console.warn( 'rdx.CrowdCampaign_Create: err: ', err )

            return Promise.reject( err )
        }
    }
}   // CrowdCampaign_Create

/**
 */
export const SaveCrowdCampaignImage = (params) =>
{
    return async (d,gs) =>
    {
        try
        {
            const aws_lambda = new AWS.Lambda( {} )
            const aws_core = new AWS.Core( {} )
            const cur_usr = await aws_core.currentuser()

            if (!cur_usr)
            {
                throw new Error("User not found!")
            }

            const lambda_name = d( Cfg.Lambda('app') )
            const imgId = [gs().__Prof.profInfo.u,params.id].join(':')
            const p_list = {
                actType: 'crowd',
                act: 'crowd:img-update',
                c_id: gs().__cfg.oId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data: {
                    img:{
                        c:{
                            f:params.url,
                            t:""
                        },
                        g:[],
                        f:[]
                    },
                    newImg:true,
                    id:params.id,
                    url:params.url,
                    album:'profile',
                    tag:['profile'],
                    imgId

                },
            }

            // console.log( 'actions/profile: SaveProfileImage: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, lambda_name )

            // console.log( 'actions/profile: SaveProfileImage: resp_list: ', resp_list )

            //
            return resp_list
        }
        catch (e)
        {
            console.warn("action/profile: SaveProfileImage:error", e)

            return Promise.reject(e);
        }
    }
}

/**
 */
export const GetProjectList= () =>
{
    return async (d,gs) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )
            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            if (!cur_usr)
            {
                throw new Error("User not found!")
            }

            const lambda_name = d( Cfg.Lambda('app') )

            const p_list = {
                actType: 'project',
                act: 'project:list',
                c_id: gs().__cfg.oId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
            }

            // console.log( 'action/crowed-camp GetProjectList: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, lambda_name )

            // console.log( 'action/crowed-camp GetProjectList: ', resp_list )
            return {success:true,...resp_list}
        }
        catch (e)
        {
            console.warn("action/crowed-camp: GetProject:err",e)

            return Promise.reject(e)
        }
    }
}

/**
 */
export const GetProjectCampaignList= (params) =>
{
    return async (d,gs)=>
    {
        try
        {
            const aws_lambda = new Lambda( {} )
            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()
            if (!cur_usr)
            {
                throw new Error("User not found!")
            }
            const lambda_name = d( Cfg.Lambda('app') )

            const p_list = {
                actType: 'campaign',
                act: 'campaign:project-campaign-list',
                c_id: gs().__cfg.oId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data:{
                    p_id:params.p_id
                }
            }

            // console.log( 'action/crowed-camp GetProjectList: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, lambda_name )

            console.log( 'action/crowed-camp GetProjectList: ', resp_list )

            return {success:true,...resp_list}
        }
        catch (e)
        {
            console.warn("action/crowed-camp: GetProject:err",e)

            return Promise.reject(e)
        }
    }
}   // GetProjectCampaignList

/**
 */
export const GetCrowdCampaignDetails= (params) =>
{
    return async (d,gs) =>
    {
        try
        {
            const aws_lambda = new AWS.Lambda( {} )
            const aws_core = new AWS.Core( {} )
            const isLoggedin = !!'loggedin' === gs().__auth.status
            let cur_usr ;
            console.log(gs())
            if (isLoggedin)
            {
                cur_usr = await aws_core.currentuser()
                if (!cur_usr)
                {
                    throw new Error("User not found!")
                }
            }

            const lambda_name = isLoggedin? d( Cfg.Lambda('app') ) : d( Cfg.Lambda('unauth') )

            const p_list = {
                actType: 'crowd',
                act: 'crowd:campaign-details',
                c_id: gs().__cfg.oId,
                usr: isLoggedin ?{
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                }:{},
                data:{
                    uid:params.uid
                }
            }

            console.log( 'action/crowed-camp GetCampaignDetails: p_list: ', p_list,lambda_name )

            const resp_list = await aws_lambda.Invoke( p_list, lambda_name )

            console.log( 'action/crowed-camp GetCampaignDetails: ', resp_list )

            return {
                success:true,
                ...resp_list,
                Item: {
                    ...resp_list.Item,
                    a: {
                        ...resp_list.Item.a,
                        t: resp_list.Item.a.t / 100.0,
                        p: resp_list.Item.a.p / 100.0,
                        r: resp_list.Item.a.r / 100.0,
                    },
                }
            }
        }
        catch (e)
        {
            console.warn("action/crowed-camp: GetCampaignDetails:err",e)

            return Promise.reject(e)
        }
    }
}

/**
 */
export const UpdateCrowdCampaign = (params) =>
{
    return async (d, gs)=>
    {
        try
        {
            const aws_lambda = new AWS.Lambda( {} )
            const aws_core = new AWS.Core( {} )
            const cur_usr = await aws_core.currentuser()
            if (!cur_usr)
            {
                throw new Error("User not found!")
            }
            console.log(params)
            const lambda_name = d( Cfg.Lambda('app') )
            const {listDesc} = params

            const newList = await listDesc.reduce(async (acc, cur, index) => {
                const accum = await acc;
                if (cur.d.file)
                {
                    const file = cur.d.file
                    const ft = file.type.split("/")[1];
                    const name = [params.i,"crowd-campaign", params.id, Date.now()].join("_") + "." + ft;
                    const params_upd = {
                        name: name,
                        key: name,
                        contentType: file.type,
                        file: file,
                    };

                    console.log("Upload params",params_upd);

                    // const { success, url } = await dispatch(uploadFile(params));
                    const resp_up = await d(uploadFile(params_upd));

                    console.log(resp_up);

                    if (resp_up.success) {
                        cur.d = {url:resp_up.url}
                        accum.push(cur)
                    }
                }else
                {
                    accum.push(cur)
                }
                return accum
            }, Promise.resolve([]));
            // console.log(newList)
            // return {}

            params.ct['listDesc'] = newList
            const upd_p = {
                actType: 'crowd',
                act: 'crowd:campaign-update',
                c_id: gs().__cfg.oId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data:{
                    ta:params.ta,
                    t:params.t,
                    tl:params.tl,
                    id:params.id,
                    i:params.i,
                    ct:params.ct,
                }
            }

            // console.log( 'actions/crowd_campaign: UpdateCrowdCampaign: p_list: ', upd_p )

            const resp_list = await aws_lambda.Invoke( upd_p, lambda_name )

            // console.log( 'actions/crowd_campaign: UpdateCrowdCampaign: resp_list: ', resp_list )
            if (params.isImgChange)
            {
                const {cc} = await d(ChangeCampaignImage(params))

                return {cc}
            }

            //
            return resp_list
        }
        catch (e)
        {
            console.warn("actions:crowd_campaign UpdateCrowdCampaign:err",e)
            return Promise.reject(e)
        }
    }
}

/**
 */
const ChangeCampaignImage = (param) =>
{
    return async (d,gs) =>
    {
        try
        {
            const ft = param.file.type.split("/")[1];
            const name = [param.i,"crowd-campaign", param.id, Date.now()].join("_") + "." + ft;
            const params = {
                name: name,
                key: name,
                contentType: param.file.type,
                file: param.file,
            };

            // console.log("Upload params",params);

            // const { success, url } = await dispatch(uploadFile(params));
            const resp_up = await d(uploadFile(params));

            console.log(resp_up);

            if (!resp_up.success) {
                throw new Error("Filed to save file!");
            }

            // const { success, cc } = await dispatch(
            const resp_save = await d(SaveCrowdCampaignImage({ url: resp_up.url, id: param.id }));

            console.log(resp_save);

            if (!resp_save.success) {
                throw new Error("Filed to save file!");
            }

            return { cc: resp_save.cc.data.Attributes };
        }
        catch (err)
        {
            console.warn("CreateCampaignSummary: saveImage: err: ", err);

            return Promise.reject(err);
        }
    }
}

/**
 */
export const CrowdCampaign_Basic = ( basic_obj ) =>
{
    return {
        type: 'crowd-campaign:basic',
        payload: basic_obj,
    }
}   // CrowdCampaign_Basic

/**
 */
export const CrowdCampaign_Project = ( project_obj ) =>
{
    return {
        type: 'crowd-campaign:project',
        payload: project_obj,
    }
}   // CrowdCampaign_Project

/**
 */
export const CrowdCampaign_Funding = ( funding_obj ) =>
{
    return {
        type: 'crowd-campaign:funding',
        payload: funding_obj,
    }
}   // CrowdCampaign_Funding

/**
 */
export const CrowdCampaign_Content = ( content_obj ) =>
{
    return {
        type: 'crowd-campaign:content',
        payload: content_obj,
    }
}   // CrowdCampaign_Content


