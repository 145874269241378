/**
 * @copyright KARD
 */

import React, { useEffect } from "react";
import "../home/style.css";
import "./style.css";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import NarrowFooter from "components/Footers/NarrowFooter.js";
import { navigate } from "@reach/router";
import {
  GetCrowdCampaignDetails,
  // Crowd_DonationList,
  Crowd_Profile,
  Campaign_Get,
} from "../../rdx/actions";

import { useDispatch } from "react-redux";

import Image from "aui/image";

import LatestDonations from "./campaign_donations";
// import { async } from "q";

/**
 */
function CrowdCampaignDetails(props) {
  // const { uid } = props;
  // const uid = decodeURIComponent( props.uid )
  const [uid] = React.useState(
    props.uid ? decodeURIComponent(props.uid) : null
  );

  // console.log(props);

  const dispatch = useDispatch();

  // const [firstFocus, setFirstFocus] = React.useState(false);
  // const [lastFocus, setLastFocus] = React.useState(false);
  const [item, setItem] = React.useState(null);
  const [baseCamp, BaseCamp] = React.useState(null);

  const [campTarget, CampTarget] = React.useState(0);
  const [campRaised, CampRaised] = React.useState(0);
  const [campRatio, CampRatio] = React.useState(0);

  const [prof, Prof] = React.useState(null);
  const [profName, ProfName] = React.useState("");
  const [profImg, ProfImg] = React.useState(null);

  const [listDesc, ListDesc] = React.useState([]);
  const [selPkg, SelPkg] = React.useState({});

  /**
   */
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  /**
   */
  useEffect(() => {
    Init().catch((err) => {});
  }, []);

  /**
   */
  const Init = async () => {
    try {
      if (!uid) {
        return {};
      }

      const camp_det = await dispatch(GetCrowdCampaignDetails({ uid }));

      console.log("CrowdCampaignDetails: Init: camp_det: ", camp_det);

      if (!camp_det.success) {
        return {};
      }

      const c_obj = camp_det.Item;

      setItem(camp_det.Item);

      if (c_obj.a && c_obj.a.t) {
        CampTarget(c_obj.a.t);
      }

      if (c_obj.a && c_obj.a.r) {
        CampRaised(c_obj.a.r);
      }

      if (c_obj.a && c_obj.a.t && c_obj.a.r) {
        if (c_obj.a.r < c_obj.a.t) {
          CampRatio(parseInt((c_obj.a.r / c_obj.a.t) * 100.0, 10));
        } else {
          CampRatio(100);
        }
      }

      if (c_obj.ct?.listDesc) {
        ListDesc(c_obj.ct.listDesc);
      }

      await Promise.all([InitProf(c_obj), InitCamp(c_obj)]);

      return {};
    } catch (err) {
      console.warn("CrowdCampaignDetails: Init: err: ", err);

      return Promise.reject(err);
    }
  }; // Init

  /**
   */
  const InitCamp = async (c_obj) => {
    try {
      const base_camp = await dispatch(
        Campaign_Get({ cp_n: c_obj.c, pr_n: c_obj.p })
      );

      console.log("CrowdCampaignDetails: InitCamp: base_camp: ", base_camp);

      if (base_camp) {
        //
      }

      BaseCamp(base_camp.Item);

      return {};
    } catch (err) {
      console.warn("CrowdCampaignDetails: InitCamp: err: ", err);

      // return Promise.reject(err);
      return { err };
    }
  }; // InitCamp

  /**
   */
  const InitProf = async (c_obj) => {
    try {
      // console.log("CrowdCampaignDetails: Init: c_obj.u: ", c_obj.u);

      const prof_creator = await dispatch(Crowd_Profile({ username: c_obj.u }));

      // console.log("CrowdCampaignDetails: Init: prof_creator: ", prof_creator);

      if (
        prof_creator.Item.n &&
        prof_creator.Item.n.f &&
        prof_creator.Item.n.l
      ) {
        ProfName([prof_creator.Item.n.f, prof_creator.Item.n.l].join(" "));
      }

      if (prof_creator.Item.img && prof_creator.Item.img.f) {
        ProfImg(prof_creator.Item.img.f);
      }

      Prof(prof_creator.Item);

      return {};
    } catch (err) {
      console.warn("CrowdCampaignDetails: InitProf: err: ", err);

      // return Promise.reject(err);
      return { err };
    }
  }; // InitProf

  function donateHandler(e) {
    e.preventDefault();
    e.stopPropagation();
    // console.log(item)

    if (!selPkg.tn) {
      alert("Please select a package.");

      return {};
    }

    navigate(
      [
        "/donation/crowd",
        encodeURIComponent(uid),
        encodeURIComponent(JSON.stringify(selPkg)),
      ].join("/")
    );
  }

  /**
   */
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="section section-campaign campaign-single">
          <Container>
            <Row className="">
              <Col lg="8" sm="6">
                <div>
                  {item && (
                    <CardBody className="pt-0 pl-0">
                      <Breadcrumb>
                        <BreadcrumbItem>
                          <a href="#">Crowd</a>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                          <a href="#">Campaign</a>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>{item?.t}</BreadcrumbItem>
                      </Breadcrumb>

                      <CardTitle
                        tag="h4"
                        className="text-uppercase"
                        style={{ marginTop: 0 }}
                      >
                        {item?.t}
                      </CardTitle>

                      <CardText style={{ fontSize: "16px" }}>
                        <p>{item.tl}</p>
                      </CardText>

                      {/* Organizer */}
                      {/* <div
                        className="text-green pb-2 pt-2"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "baseline",
                          borderBottom: "1px dashed #e9ecef",
                          marginBottom: 10,
                        }}
                      >
                        <div className="donor-img">
                          <>
                            {profImg ? (
                              <img
                                alt="Profile Image"
                                className="responsive mr-3"
                                src={profImg}
                                style={{
                                  borderRadius: "50%",
                                  flexShrink: 0,
                                  height: "2.5em",
                                  width: "2.5em",
                                }}
                              />
                            ) : (
                              <img
                                alt="Avatar"
                                className="responsive mr-3"
                                src={require("assets/img/neutral.jpg").default}
                                style={{
                                  borderRadius: "50%",
                                  flexShrink: 0,
                                  width: "30px",
                                  height: "2.5em",
                                  width: "2.5em",
                                }}
                              />
                            )}
                          </>
                        </div>
                        <div className="donor-name-amnt">
                          <a>
                            <b> {profName}</b> is organizing this fundraiser.
                          </a>
                        </div>
                      </div> */}
                      {/* End of Organizer */}

                      <Image
                        cn="rounded card-img"
                        style={{
                          width: "100%",
                          maxHeight: "150px",
                          minHeight: "300px",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                        src={item?.ct?.img?.c?.f}
                        alt={require("assets/img/placeholder.png").default}
                      />

                      {/* <CardText style={{ fontSize: "16px" }}>
                        <p>{item.tl}</p>
                      </CardText> */}
                    </CardBody>
                  )}

                  {/*
                  <a
                    // href=""
                    className="btn btn-lg btn-block btn-green text-white"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      // console.log(item)

                      navigate(
                        ["/donation/crowd", encodeURIComponent(item.uid)].join(
                          "/"
                        )
                      );
                    }}
                  >
                    Donate
                  </a>

                  <>
                    {baseCamp?.pk && (
                      <DonationPkg pk={baseCamp.pk} uid={item.uid} />
                    )}
                  </>
                  */}

                  <ProcessDonation
                    baseCamp={baseCamp}
                    item={item}
                    selectPack={SelPkg}
                    value={selPkg}
                    donateHandler={donateHandler}
                  />

                  <>
                    {listDesc.map((x) => (
                      <DescComp key={x.tn} {...x} />
                    ))}
                  </>
                  <a
                    href="https://pay.vivawallet.com/littlehope"
                    className="btn btn-lg btn-block btn-green text-white"
                    // onClick={donateHandler}
                  >
                    Donate
                  </a>
                  <div className="social-share text-center mt-5">
                    <>
                      {item?.ct?.socFb && item.ct.socFb.length && (
                        <a href={item.ct.socFb}>
                          <i
                            className="fab fa-facebook-f fa-lg mr-3"
                            aria-hidden="true"
                          ></i>
                        </a>
                      )}
                    </>

                    <>
                      {item?.ct?.socInsta && item.ct.socInsta.length && (
                        <a href={item.ct.socInsta}>
                          <i
                            className="fab fa-instagram fa-lg mr-3"
                            aria-hidden="true"
                          ></i>
                        </a>
                      )}
                    </>

                    <>
                      {item?.ct?.socTwit && item.ct.socTwit.length && (
                        <a href={item.ct.socTwit}>
                          <i
                            className="fab fa-twitter fa-lg mr-3"
                            aria-hidden="true"
                          ></i>
                        </a>
                      )}
                    </>

                    <>
                      {item?.ct?.socYT && item.ct.socYT.length && (
                        <a href={item.ct.socYT}>
                          <i
                            className="fab fa-youtube fa-lg mr-3"
                            aria-hidden="true"
                          ></i>
                        </a>
                      )}
                    </>
                  </div>
                </div>
              </Col>
              <Col lg="4" sm="6" style={{ marginTop: "74px" }}>
                <Card>
                  <>
                    {profImg ? (
                      <img
                        alt="Profile Image"
                        style={{
                          minHeight: 200,
                          maxHeight: 200,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                        className="responsive"
                        src={profImg}
                      ></img>
                    ) : (
                      <img
                        alt="Profile Image"
                        className="responsive"
                        style={{
                          minHeight: 200,
                          maxHeight: 200,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                        src={require("assets/img/neutral.jpg").default}
                      ></img>
                    )}
                  </>
                  <CardBody style={{ paddingBottom: 0 }}>
                    {/* <CardTitle tag="h5" style={{ margin: 0 }}>
                      {profName}
                    </CardTitle> */}
                    {/* <CardText>{demo_creator.desc}</CardText> */}
                    {/* <p>Last donate {item.lastdonatehr} hr ago</p> */}
                  </CardBody>

                  <CardBody style={{ padding: "10px 21px 0" }}>
                    <div className="progress mb-2">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${campRatio}%` }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <h5 style={{ fontSize: "16px" }}>
                      <span style={{ fontSize: "22px", fontWeight: 700 }}>
                        {"£" + campRaised.toFixed(2)}
                      </span>{" "}
                      raised of {"£" + campTarget.toFixed(2)} goal
                    </h5>
                    {/* <h5 style={{ fontSize: "16px", fontWeight: 600 }}>
                      Raised: {"£" + campRaised.toFixed(2)}
                    </h5>
                    <h5 style={{ fontSize: "16px", fontWeight: 600 }}>
                      Target: {"£" + campTarget.toFixed(2)}
                    </h5> */}
                  </CardBody>
                </Card>

                <LatestDonations
                  id={item && item.id}
                  donateHandler={donateHandler}
                />
              </Col>
            </Row>
          </Container>
        </div>

        <NarrowFooter />
      </div>
    </>
  );
}

/**
 */
export default CrowdCampaignDetails;

/**
 */
const DescComp = (props) => {
  const [type, Type] = React.useState(props.t); // txt / img / vidUrl
  const [text, Text] = React.useState("txt" === props.t ? props.d : "");
  // const [imgDoc, ImgDoc] = React.useState( props.d );

  return (
    <>
      <>
        {"txt" === type ? (
          <p style={{ fontSize: 14 }}>{text}</p>
        ) : "img" === type ? (
          <div className="py-3">
            <Image
              src={props?.d.url}
              alt={"assets/img/placeholder.png"}
              altTxt="image"
              cn="border"
              style={{
                width: "100%",
                backgroundSize: "cover",
              }}
            />
          </div>
        ) : "vid" === type ? (
          <div>{/*  */}</div>
        ) : null}
      </>
    </>
  );
}; // DescComp

/**
 */
const ProcessDonation = (props) => {
  // const [selPkg, SelPkg] = React.useState({});

  return (
    <>
      <>
        {props.baseCamp?.pk && (
          <DonationPkg
            pk={props.baseCamp.pk}
            uid={props.item.uid}
            sel={props.value}
            OnSel={(pkg) => props.selectPack(pkg)}
          />
        )}
      </>

      <a
        href="https://pay.vivawallet.com/littlehope"
        className="btn btn-lg btn-block btn-green text-white"
        // onClick={props.donateHandler}
      >
        Donate
      </a>
    </>
  );
}; // ProcessDonation

/**
 */
const DonationPkg = (props) => {
  const [listPkg, ListPkg] = React.useState([]);

  React.useEffect(() => {
    // console.log("DonationPkg: props: ", props);
    // console.log("DonationPkg: props.pk: ", props.pk);

    ListPkg(props.pk ? props.pk : []);
  }, [props.pk]);

  return (
    <>
      {/* <div className="d-lg-flex justify-content-around" href="#donation">
        {listPkg.map((x) => (
          <a
            key={x.tn}
            // href=""
            className="btn btn-lg btn-green-alt"
            style={{
              backgroundColor: props.sel.tn === x.tn ? "lightgreen" : null,
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              // console.log(item)

              props.OnSel(x);

              // navigate(
              //   ["/donation/crowd", encodeURIComponent(props.uid), x.tn].join(
              //     "/"
              //   )
              // );
            }}
          >
            {"£" + x.a}
          </a>
        ))}

        <>
          <a
            // key={x.tn}
            // href=""
            className="d-block"
            // style={{
            //   backgroundColor: props.sel.tn === -1 ? "lightgreen" : null,
            // }}
            // onClick={(e) => {
            //   e.preventDefault();
            //   e.stopPropagation();
            //   // console.log(item)

            //   console.log( 'other: e.target.value: ', e.target.value )

            //   props.OnSel({ n: "other", tn: -1, a: parseInt(e.target.value, 10), p: "one" });

            //   // navigate(
            //   //   ["/donation/crowd", encodeURIComponent(props.uid), x.tn].join(
            //   //     "/"
            //   //   )
            //   // );
            // }}
          >
            <input
              className="form-control"
              style={{ padding: 12, margin: 10 }}
              placeholder="£Other"
              // value={}
              // onChange={}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                // console.log(item)

                console.log( 'other: e.target.value: ', e.target.value )

                props.OnSel({ n: "other", tn: -1, a: parseInt(e.target.value, 10), p: "one" });

                // navigate(
                //   ["/donation/crowd", encodeURIComponent(props.uid), x.tn].join(
                //     "/"
                //   )
                // );
              }}
            />
          </a>
        </>
      </div> */}

      <>
        {
          //
        }
      </>
    </>
  );
}; // DonationPck
