import React from "react";
import "./style.css";
// reactstrap components

import {
  Button,
  Form,
  Input,
  FormGroup,
  Container,
  Col,
  Row,
  InputGroup,
  UncontrolledCollapse,
  CardBody,
  Card,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import NarrowFooter from "components/Footers/NarrowFooter.js";

function Contact() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "50vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/contact.jpg").default + ")",
            }}
          ></div>
          <div className="content-center">
            <Container>
              <h3 href="#" className="text-white h3" style={{ fontSize: 46 }}>
                Contact Us
              </h3>
            </Container>
          </div>
        </div>
        <div className="section">
          <Container>
            <Row>
              <Col
                sm="12"
                className="d-flex align-content-center justify-content-center mb-5"
              >
                <div className="btn-group">
                  <Button className="btn btn-green btn-lg btn-hope mr-5">
                    Send a Message
                  </Button>
                  <Button className="btn btn-green btn-lg btn-hope">
                    Make a Donation
                  </Button>
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm="6">
                <div className="getTouch">
                  <p className="pDark">GET IN TOUCH</p>

                  <p
                    style={{ fontSize: 35, color: "#333333", fontWeight: 700 }}
                  >
                    Contact us Anytime!
                  </p>
                  <p className="pDetails">
                    At SEND A LITTLE HOPE, we believe in two things Integrity
                    and Accountability.
                  </p>
                  <p className="pDark">Integrity</p>

                  <p className="pDetails">
                    We work within the frame of trust. Trust among our donor,
                    beneficiaries, funder and partner.
                  </p>

                  <p className="pDark">Accountability</p>

                  <p className="pDetails">
                    We consider our work and dealing have dual accountabilities.
                    One with the Integrity on earth in our everyday operation
                    and the other is ultimate, with ALLAH, the almighty.
                  </p>
                </div>
              </Col>
              <Col sm="6">
                <div className="frequent">
                  <p className="pDark">FREQUENTLY ASKED QUESTIONS</p>

                  <div className="collapseTab" id="linkToggler">
                    GIFT AID MY DONATION?
                  </div>
                  <UncontrolledCollapse toggler="#linkToggler">
                    <Card>
                      <CardBody className="collapseDetails">
                        It really helps us if you can Gift Aid your sponsorship
                        or donation – it increases the value of your donation by
                        25%. If you are a UK taxpayer, selecting to ‘Gift Aid’
                        your donation will make it worth more to us at no extra
                        cost to you. That means SEND A LITTLE HOPE can reclaim
                        25p of tax on every £1 that you give!
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>

                  <div className="collapseTab mt-4" id="linkToggler2">
                    WHICH COUNTRIES DOES SEND A LITTLE HOPE WORK IN AND WHO
                    BENEFITS?
                  </div>
                  <UncontrolledCollapse toggler="#linkToggler2">
                    <Card>
                      <CardBody className="collapseDetails">
                        We support people in desperate need in Somalia, Kenya
                        and Ethiopia. We focus our efforts on delivering aid
                        directly to the most vulnerable and marginal member of
                        the society. Our teams on the ground co-ordinate the
                        delivery of aid to ensure it gets to the people who so
                        desperately need it as quickly as possible.
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>

                  <div className="collapseTab mt-4" id="linkToggler3">
                    WHAT DOES THE FOOD PACK CONTAIN?
                  </div>
                  <UncontrolledCollapse toggler="#linkToggler3">
                    <Card>
                      <CardBody className="collapseDetails">
                        This varies from country to country, depending on the
                        specific needs of a location. Typically, a food pack
                        contains the following foods: rice, flour, oil, sugar,
                        lentils, pasta, canned tomatoes and tea.
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>

                  <div className="collapseTab mt-4" id="linkToggler4">
                    CAN I MAKE A REGULAR PAYMENT?
                  </div>
                  <UncontrolledCollapse toggler="#linkToggler4">
                    <Card>
                      <CardBody className="collapseDetails">
                        Yes, small payments made regularly can make a big
                        difference – this is the SEND A LITTLE HOPE’s way. You
                        can set up an affordable monthly Direct Debit to make
                        contributions to our Hifz, Orphan or Livelihood for
                        Widows programmes. Automatic, monthly payments are the
                        most efficient way to support SEND A LITTLE HOPE they
                        reduce the cost of administration. Regular commitments
                        allow us to plan ahead and ensure we have a reliable
                        source of income to provide aid.
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>

                  <div className="collapseTab mt-4" id="linkToggler5">
                    IS MY PAYMENT SECURE?
                  </div>
                  <UncontrolledCollapse toggler="#linkToggler5">
                    <Card>
                      <CardBody className="collapseDetails">
                        We only use secure systems with banking standard safety
                        features thjat is GDPR approved to make sure that your
                        payment and financial data cannot be compromised. All
                        online payments are securely processed via Stripe and
                        GoCardLess, GDPR approved means no financial data is
                        ever retained in our system.
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>
              </Col>
            </Row>

            <Row className="mt-3 form-littlehope">
              <Col sm="12">
                <p style={{ fontSize: 26, color: "#026666", fontWeight: 700 }}>
                  Send us a message!
                </p>
              </Col>

              <Col sm="6">
                <FormGroup className="col p-0">
                  <Input
                    className=" mr-4"
                    id="name"
                    placeholder="Name"
                    type="text"
                  ></Input>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup className="col p-0">
                  <Input
                    className=""
                    id="email"
                    placeholder="Email Address"
                    type="email"
                  ></Input>
                </FormGroup>
              </Col>
              <Col sm="12">
                <FormGroup>
                  <Input
                    className="mt-2"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    placeholder="Message"
                    type="textarea"
                  ></Input>
                </FormGroup>

                <Button className="btn btn-green pull-right" type="submit">
                  SEND
                </Button>
              </Col>
            </Row>
          </Container>
        </div>

        <NarrowFooter />
      </div>
    </>
  );
}

export default Contact;
