import React from "react";
import "../home/style.css";
import "./style.css";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import {navigate} from "@reach/router";
const items = [
  {
    src: require("assets/img/slider/slider-2.png").default,
    creator: require("assets/img/m-avatar.png").default,
    title: "Eye Camp in Bangladesh",
    title2: "Gabe's memorial fund",
    description: "",
    lastdonatehr: "9",
    targetamount: "1000",
    receiveamount: "220",
  },
];
function EyeCamp(props) {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [item,setItem] = React.useState(props.location.state ? props.location.state.item : {} )
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="section section-campaign campaign-single">
          <Container>
            <Row className="">
              <Col lg="9" sm="6">
                    <div>
                      <CardBody className="pt-0 pl-0">
                        <CardTitle tag="h4" className="text-uppercase">
                          {item.t}
                        </CardTitle>
                        <img
                          alt="..."
                          className="responsive mb-3"
                          src={item.ct.img ? item.ct.img.c.f: ''}
                        ></img>
                        <CardText style={{ fontSize: "16px" }}>
                          <p>
                            {item.tl}
                          </p>
                         {/* <p>
                            Eye problems among underprivileged people are one of
                            the most neglected issues in Bangladesh.
                          </p>
                          <p>
                            According to the National Blindness and Low Vision
                            Survey of Bangladesh, 1.53% of adults, whose age is
                            at least 30 years, are blind and 21.6% have low
                            vision.  
                          </p>
                          <p>
                            Limited access to health care is one of the reasons
                            poor people get more vulnerable to these disabling
                            conditions.  
                          </p>
                          <p>
                            You can be the eye for these people. Please donate
                            to our Eye Camp in Bangladesh.   Eye Camp in
                            Bangladesh: £500  
                          </p>
                          <p>
                            Your donation can enable 100 individuals to see the
                            world again.
                          </p>*/}
                        </CardText>
                      </CardBody>
                    </div>
              </Col>
              <Col lg="3" sm="6">
                {items.map((item) => {
                  return (
                    <Card>
                      <img
                        alt="..."
                        className="responsive"
                        src={item.creator}
                      ></img>
                      <CardBody>
                        <CardTitle tag="h5">Created By: Jon Doe</CardTitle>
                        <CardText>{item.description}</CardText>
                        <p>Last donate {item.lastdonatehr} hr ago</p>
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: "70%" }}
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <CardTitle tag="h3" className="font-weight-bold">
                          {item.receiveamount}$ Raised
                          <span className="text-muted">
                            of {item.targetamount}$
                          </span>
                        </CardTitle>
                      </CardBody>
                    </Card>
                  );
                })}

                {items.map((item) => {
                  return (
                    <Card>
                      <CardBody>
                        <CardTitle tag="h5">Recent Donator</CardTitle>
                        <CardText>{item.description}</CardText>
                        <div>
                          <p className="font-weigh-bold text-green">
                            Sarwar Hossain $ 50
                          </p>
                          <p className="font-weigh-bold text-green">
                            Arif Hossain $ 50{" "}
                          </p>
                          <p className="font-weigh-bold text-green">
                            Ryan Hossain $ 50{" "}
                          </p>
                          <p className="font-weigh-bold text-green">
                            Asif Hossain $ 50{" "}
                          </p>
                          <p className="font-weigh-bold text-green">
                            Koushik Hossain $ 50{" "}
                          </p>
                        </div>
                      </CardBody>
                    </Card>
                  );
                })}
                <a href="" class="btn btn-lg btn-block btn-green"  onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  // console.log(item)

                  navigate(['/donation/ext',item.p,item.c].join('/'));
                }}>
                  Donate Now
                </a>
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default EyeCamp;
