import React from "react";
import "./style.css";
// reactstrap components

import {
  Button,
  Container,
  Col,
  Row,
  UncontrolledCollapse,
  CardBody,
  Card,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import NarrowFooter from "components/Footers/NarrowFooter.js";

function Appeals() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "50vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/contact.jpg").default + ")",
            }}
          ></div>
          <div className="content-center">
            <Container>
              <h3 href="#" className="text-white h3" style={{ fontSize: 46 }}>
                Appeals
              </h3>
            </Container>
          </div>
        </div>
        <div className="section">
          <Container>
            <Row>
              <Col>
                <div className="appeals h-100">
                  <h2 className="appeal-title">Building a Masjid</h2>
                  <img
                    alt="..."
                    className="rounded appeal-img"
                    src={require("assets/img/campaigns/masjid.png").default}
                  ></img>
                  <p className="appeal-description">
                    Islamic history shows us that the Mosque has long been
                    considered the focal point of any community.
                  </p>
                  <Button
                    className="btn btn-green"
                    style={{ fontSize: 20, fontWeight: 700 }}
                  >
                    Donate
                  </Button>
                </div>
              </Col>
              <Col>
                <div className="appeals h-100">
                  <h2 className="appeal-title">Water Well</h2>
                  <img
                    alt="..."
                    className="rounded appeal-img"
                    src={require("assets/img/campaigns/water.png").default}
                  ></img>
                  <p className="appeal-description">
                    Poor access to water or droughts can cause consequential
                    effects, resulting in failing crops, poor health and
                    malnutrition.
                  </p>
                  <Button
                    className="btn btn-green"
                    style={{ fontSize: 20, fontWeight: 700 }}
                  >
                    Donate
                  </Button>
                </div>
              </Col>
              <Col>
                <div className="appeals h-100">
                  <h2 className="appeal-title">Dental Clinic</h2>
                  <img
                    alt="..."
                    className="rounded appeal-img"
                    src={require("assets/img/campaigns/clinic.png").default}
                  ></img>
                  <p className="appeal-description">
                    Many people are suffering from oral diseases in Somalia and
                    Kenya. SEND A LITTLE HOPE is providing treatment to those
                    people of Somalia and Kenya.
                  </p>
                  <Button
                    className="btn btn-green"
                    style={{ fontSize: 20, fontWeight: 700 }}
                  >
                    Donate
                  </Button>
                </div>
              </Col>
              <Col>
                <div className="appeals h-100">
                  <h2 className="appeal-title">Emergency Food</h2>
                  <img
                    alt="..."
                    className="rounded appeal-img"
                    src={require("assets/img/campaigns/food.png").default}
                  ></img>
                  <p className="appeal-description">
                    Mapping food insecure areas, Support resident-driven
                    processes to guide equitable food policy.
                  </p>
                  <Button
                    className="btn btn-green"
                    style={{ fontSize: 20, fontWeight: 700 }}
                  >
                    Donate
                  </Button>
                </div>
              </Col>
              <Col>
                <div className="appeals h-100">
                  <h2 className="appeal-title">Emergency Water</h2>
                  <img
                    alt="..."
                    className="rounded appeal-img"
                    src={
                      require("assets/img/campaigns/emergency-water.png")
                        .default
                    }
                  ></img>
                  <p className="appeal-description">
                    Provide water to bring life into existence, to deliver water
                    we need water truck to deliver them to peoples door steps.
                  </p>
                  <Button
                    className="btn btn-green"
                    style={{ fontSize: 20, fontWeight: 700 }}
                  >
                    Donate
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <NarrowFooter />
      </div>
    </>
  );
}

export default Appeals;
