/**
 *  @copyright KARD
 */

import React from "react";

import { Router, navigate } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "rdx/actions";

// import {MLoader} from '../../aui/loader'

import AuthLogin from "views/auth/login";
// import AuthLock from '../auth/lock'
// import AuthChange from '../auth/pw_change'
// import AuthComplete from '../auth/pw_complete'
import AuthRegister from "views/auth/register";
import AuthReset from "views/auth/reset";
// import AuthRegister2 from 'views/auth/register2'
import AuthForgotten from "views/auth/forgotten";

import Home from "views/home";
import Profile from "views/profile";
// import Projects from '../projects'

// Crowd
import Crowd from "views/crowd";
import CrowdCampaignEdit from "views/crowd/campaign_edit";
import CrowdCampaignAll from "views/crowd/campaign_all";

import CrowdCampaign from "views/crowd_campaign";
import CrowdCampaignDetails from "views/crowd/campaign";
import CrowdCampaign_Create from "views/crowd_campaign/create";
import CrowdCampaign_Create_Basic from "views/crowd_campaign/create_basic";
import CrowdCampaign_Create_Funding from "views/crowd_campaign/create_funding";
import CrowdCampaign_Create_Category from "views/crowd_campaign/create_category";
import CrowdCampaign_Create_Content from "views/crowd_campaign/create_content";
import CrowdCampaign_Create_Summary from "views/crowd_campaign/create_summary";
import CrowdCampaign_Create_Thanks from "views/crowd_campaign/create_thanks";

import Campaign from "views/campaign";
import All_Campaign from "views/campaign/all_campaign";
import Single_Campaign from "views/campaign/single";
import EyeCamp from "views/campaign/single_camp_eye_camp";
import WaterWell from "views/campaign/single_camp_water_well";
// import Campaign_Start from "views/campaign/start";
// import Campaign_Edit from "views/campaign/edit";

// Profile
import UserProfile from "views/profile/";

// Donation
import Donation from "views/donation";
import CrowdDonation from "views/donation/crowd_donation";
import DonationSuccess from "views/donation/donation_success";

// Pages

import Contact from "views/pages/contact";
import Appeals from "views/pages/appeals";
import BuidlingMasjid from "views/pages/masjid";
import BuildingWaterWell from "views/pages/water";
import BuildingDentalClinic from "views/pages/clinic";
import BuildingTubewell from "views/pages/tubewell";
import BuildingEyeCamp from "views/pages/eyecamp";

// import Campaign from '../camp../crowd/start_edit

// import Shop from '../shop'

//
// import P500 from '../_common/p500'
// import P404 from '../_common/p404'

/**
 */
const AppNav = (props) => {
  /**
   */
  React.useEffect(() => {
    VerifyAuth()
      .then(() => {
        // props.Campaign_TypeList().catch( err => {} )
      })
      .catch(console.warn);
  }, []);

  /**
   */
  const VerifyAuth = async () => {
    try {
      const bLoggedin = await props.Auth_Verify();

      // console.log( 'AppNav: VerifyAuth: bLoggedin: ', bLoggedin )

      return bLoggedin;
    } catch (err) {
      console.warn("AppNav: VerifyAuth: err: ", err);

      return Promise.reject(err);
    }
  }; // VerifyAuth

  /**
   */
  return (
    <Router>
      {/* <Home path="/" /> */}
      {/* <Home path="/home" /> */}
      <Crowd path="/" />
      <Crowd path="/crowd" />
      <CrowdCampaignEdit path="/crowd/campaign/edit/:uid" />
      <CrowdCampaignAll path="/crowd/campaign/all" />
      <CrowdCampaignDetails path="/crowd/campaign/:uid" />

      <CrowdCampaign_Create path="/crowd/campaign/create" />
      {/* <CrowdCampaign_Create path="/crowd/campaign/create/:pr_n/:cp_n" /> */}
      <CrowdCampaign_Create_Basic path="/crowd/campaign/create/basics" />
      <CrowdCampaign_Create_Basic path="/crowd/campaign/create/basics/:pr_n/:cp_n" />
      <CrowdCampaign_Create_Category path="/crowd/campaign/create/category" />
      <CrowdCampaign_Create_Funding path="/crowd/campaign/create/funding" />
      <CrowdCampaign_Create_Content path="/crowd/campaign/create/content" />
      <CrowdCampaign_Create_Summary path="/crowd/campaign/create/summary" />
      <CrowdCampaign_Create_Thanks path="/crowd/campaign/create/thanks" />

      {/* <Projects path='/projects' /> */}
      <Donation path="/donation" />
      <DonationSuccess path="/donation/success" />
      {/* <Donation path="/donation/nowandnow" /> */}
      <Donation path="/donation/ext" />
      <Donation path="/donation/ext/:pr_n" />
      <Donation path="/donation/ext/:pr_n/:cp_n" />
      <Donation path="/donation/ext/:pr_n/:cp_n/:pck_n" />
      <CrowdDonation path="/donation/crowd/:uid" />
      <CrowdDonation path="/donation/crowd/:uid/:pck" />

      <Campaign path="/campaign" />
      <All_Campaign path="/all_campaign" />
      <Single_Campaign path="/campaign/single" />
      <EyeCamp path="/campaign/single_camp_eye_camp" />
      <WaterWell path="/campaign/single_camp_water_well" />
      {/* <Campaign_Start path="/campaign/start" />
      <Campaign_Edit path="/campaign/edit" /> */}

      {/* Pages */}

      <Contact path="/about/contact" />
      <Appeals path="/appeals" />
      <BuidlingMasjid path="/masjid" />
      <BuildingWaterWell path="/water" />
      <BuildingDentalClinic path="/dental" />
      <BuildingTubewell path="/tubewell" />
      <BuildingEyeCamp path="/eyecamp" />

      <UserProfile path="/profile" />
      <AuthLogin path="/" />
      <AuthLogin path="/auth" />
      <AuthLogin path="/auth/login" />
      {/* <AuthLock path='/auth/lock' /> */}
      {/* <AuthChange path='/auth/change' /> */}
      {/* <AuthComplete path='/auth/complete' /> */}
      <AuthRegister path="/auth/register" />
      <AuthReset path="/auth/reset" />
      {/* <AuthRegister2 path='/auth/register2' /> */}
      <AuthForgotten path="/auth/forgotten" />
      {/* <P500 path='/505' />
            <P404 default /> */}
    </Router>
  );
}; // AppNav

// export default AppNav

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(AppNav);
