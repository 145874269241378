/**
 *	@copyright	KARD.
 */

/**
 */
exports.lambda = ( cn, stage ) => {
    switch (stage)
    {
        case 'live':
            switch (cn)
            {
                case 'bd':
                case 'sg':
                case 'mm':
                    return {
                        app: 'mi-charityman-live-app-QgL8BHc0CZ4f',
                        unauth: 'mi-charityman-live-unauth-0hXeumYOlhjX',
                    }
                case 'uk':
                    return {
                        app: 'mi-charityman-live-app-QgL8BHc0CZ4f',
                        unauth: 'mi-charityman-live-unauth-0hXeumYOlhjX',
                    }
                default:
                    return null
            }

        case 'test':
            switch (cn)
            {
                case 'bd':
                case 'sg':
                case 'mm':
                    return {
                        app: 'mi-charityman-test-app-v5Zy1DpxRU3l',
                        unauth: 'mi-charityman-test-unauth-Ky4gvetIWI6k',
                    }
                case 'uk':
                    return {
                        app: 'mi-charityman-test-app-v5Zy1DpxRU3l',
                        unauth: 'mi-charityman-test-unauth-Ky4gvetIWI6k',
                    }
                default:
                    return null
            }
        default: {}
    }   // Lambda
}   // lambda

