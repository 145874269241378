import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function StartCampaign() {
  return (
    <>
      <div className="section start-fundraiser">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <Col lg="8" md="12">
              <h2 className="title">Ready to start fundraising ?</h2>
              <button className="btn btn-info btn-lg "
                onClick={ e => {
                  e.preventDefault()
                  e.stopPropagation()

                  // navigate( '/campaign/start' )
                } }
              >
                Start A Campaign
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default StartCampaign;
