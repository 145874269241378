/**
 *  @copyright KARD.
 */

exports.core = {
    stage: 'live',
    // stage: 'test',
    cn: 'uk',
    // o_id: 'techali',
    o_id: 'givealittlehope',

}


