/**
 *	@copyright	KARD.
 */

import {combineReducers} from 'redux'
import { persistReducer } from 'redux-persist'

import storage from 'redux-persist/lib/storage'

import Cfg from './Cfg'
import Auth from './Auth'

import Crowd from './Crowd'
import CrowdCampaign from './CrowdCampaign'

// import Campaign from './Campaign'

import Tmp from './Tmp'
import Prof from './Profile'

/**
 */
const persist_cfg = {
    key: 'root',
    storage,

    whitelist: [
            '__ccamp'
        ],
}

/**
 */
const rdx_reduers = combineReducers( {
    __cfg: Cfg,
    __auth: Auth,

    __Prof: Prof,

    // __camp: Campaign,
    __crowd: Crowd,
    __ccamp: CrowdCampaign,

    __Tmp: Tmp,

} )

/**
 */
export default persistReducer( persist_cfg, rdx_reduers )
// export default rdx_reduers
