import React from "react";
import "./style.css";

import { navigate, Link } from "@reach/router";
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Row,
  Col,
} from "reactstrap";

import { connect, useDispatch, useSelector } from "react-redux";
// import * as actions from "rdx/actions";
import { Crowd_List } from "rdx/actions";

// core components

/**
 */
function FundraisersLittlehope(props) {
  const dispatch = useDispatch();

  const rdx_crowd = useSelector((state) => state.__crowd);

  const [list, List] = React.useState([]);

  /**
   */
  React.useEffect(() => {
    if (!rdx_crowd.list.length) {
      dispatch(Crowd_List({ b_more: false }));
    }
  }, []);

  /**
   */
  React.useEffect(() => {
    List(rdx_crowd.list.slice(0, 6));
  }, [rdx_crowd.list]);

  /**
   */
  return (
    <>
      <div className="section section-fundraisers mt-5" id="basic-elements">
        <Container>
          <Row className="d-flex align-items-center justify-content-between">
            <Col sm="8" md="8">
              <h2 className="title">Latest crowd funding campaigns </h2>
            </Col>
            <Col sm="4" md="4">
              <button
                className="btn btn-green pull-right"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  navigate("/crowd/campaign/all");
                }}
              >
                View All Campaign
              </button>
            </Col>
          </Row>

          <Row>
            {
              // props.list.map((item) => {
              // props.__crowd.list.map((item) => {
              list.map((item) => {
                return (
                  <div className="col-md-4 col-sm-12">
                    <Card>
                      {item?.ct?.img?.c ? (
                        <img
                          alt="..."
                          className="rounded card-img"
                          src={item.ct.img.c.f}
                        ></img>
                      ) : (
                        <img
                          alt="..."
                          className="rounded card-img"
                          src={require("assets/img/placeholder.png").default}
                        ></img>
                      )}
                      <CardBody>
                        <CardTitle
                          tag="h4"
                          className="text-uppercase mt-0"
                          style={{ maxHeight: "150px" }}
                        >
                          {item.t}
                        </CardTitle>
                        <CardTitle tag="h5">{item.title2}</CardTitle>
                        {/* <CardText className="fund-description">
                          {item.tl}
                        </CardText> */}

                        <CardTitle tag="h3" className="font-weight-bold">
                          <a
                            class="btn btn-sm btn-danger text-white"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              navigate("https://pay.vivawallet.com/littlehope");
                            }}
                          >
                            Donate
                          </a>
                          <a
                            class="btn btn-sm btn-green text-white"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              navigate(
                                "/crowd/campaign/" +
                                  encodeURIComponent(item.uid)
                              );
                            }}
                          >
                            View Details
                          </a>
                        </CardTitle>
                      </CardBody>
                    </Card>
                  </div>
                );
              })
            }
          </Row>

          {/* <Row lg="12">
            <div className="col text-center">
              <button className="btn btn-md btn-green text-center">
                <a href="/campaign" class="text-white">
                  All campaign
                </a>
              </button>
            </div>
          </Row>*/}
        </Container>
      </div>
    </>
  );
} // FundraisersLittlehope

/**
 */
export default FundraisersLittlehope;
// /**
//  */
// const mapStateToProps = (state) => {
//   return state;
// }; //

// /**
//  */
// export default connect(mapStateToProps, actions)(FundraisersLittlehope);
