import React from "react";
import "./style.css";

import { navigate, Link } from "@reach/router";
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Row,
  Col,
} from "reactstrap";

import { connect, useDispatch, useSelector } from "react-redux";

import { Crowd_List } from "rdx/actions";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import CharityFooter from "components/Footers/NarrowFooter";

/**
 */
function CampaignAll(props) {
  const dispatch = useDispatch();

  const rdx_crowd = useSelector((state) => state.__crowd);

  // const [isBusy, IsBusy] = React.useState(false);
  // const [list, List] = React.useState([]);
  // const [lastKey, LastKey] = React.useState(null);

  React.useEffect(() => {
    // Load().catch((err) => {});

    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  /**
   */
  React.useEffect(() => {
    if (!rdx_crowd.list.length) {
      dispatch(Crowd_List({ b_more: false }));
    }
  }, []);

  // /**
  //  */
  // const Load = async () => {
  //   try {
  //     IsBusy(true);

  //     // const resp_list = await props.Crowd_List({ last_key: lastKey });
  //     const resp_list = await dispatch( Crowd_List({ last_key: lastKey }) );

  //     console.log("crowd.index: Load: resp_list: ", resp_list);

  //     List([...list, ...resp_list.Items]);
  //     LastKey(resp_list.LastEvaluatedKey);

  //     IsBusy(false);

  //     return {};
  //   } catch (err) {
  //     console.warn("crowd.index: Load: err: ", err);

  //     IsBusy(false);
  //     return { err };
  //   }
  // }; // Load

  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="main" style={{ minHeight: "78vh" }}>
          <div className="section section-fundraisers mt-5" id="basic-elements">
            <Container>
              <Row className="d-flex align-items-center justify-content-between">
                <Col sm="12" md="8">
                  <h2 className="title">Crowd funding campaigns </h2>
                </Col>
              </Row>

              <Row>
                {
                  // list.map((item) => {
                  rdx_crowd.list.map((item) => {
                    return (
                      <div className="col-md-4 col-sm-12">
                        <Card>
                          {item?.ct?.img?.c ? (
                            <img
                              alt="..."
                              className="rounded card-img"
                              src={item.ct.img.c.f}
                            ></img>
                          ) : (
                            <img
                              alt="..."
                              className="rounded card-img"
                              src={
                                require("assets/img/placeholder.png").default
                              }
                            ></img>
                          )}
                          <CardBody>
                            <CardTitle
                              tag="h4"
                              className="text-uppercase mt-0"
                              style={{ maxHeight: "150px" }}
                            >
                              {item.t}
                            </CardTitle>
                            <CardTitle tag="h5">{item.title2}</CardTitle>
                            {/* <CardText className="fund-description">
                              {item.tl}
                            </CardText> */}

                            <CardTitle tag="h3" className="font-weight-bold">
                              <a
                                class="btn btn-sm btn-danger text-white"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  navigate(
                                    "https://pay.vivawallet.com/littlehope"
                                  );
                                }}
                              >
                                Donate
                              </a>
                              <a
                                class="btn btn-sm btn-green text-white"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  navigate(
                                    "/crowd/campaign/" +
                                      encodeURIComponent(item.uid)
                                  );
                                }}
                              >
                                View Details
                              </a>
                            </CardTitle>
                          </CardBody>
                        </Card>
                      </div>
                    );
                  })
                }
              </Row>

              <Row lg="12">
                <div className="col">
                  {
                    // lastKey &&
                    rdx_crowd.last && (
                      <button
                        className="btn btn-md btn-green"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          // Load().catch( err => {} )
                          dispatch(Crowd_List({ b_more: true }));
                        }}
                      >
                        <a href="/" class="text-white">
                          {rdx_crowd.isLoading ? "Loading..." : "Show More"}
                        </a>
                      </button>
                    )
                  }
                </div>
              </Row>
            </Container>
          </div>
        </div>
        <CharityFooter />
      </div>
    </>
  );
}

export default CampaignAll;
