import React, {useState, useEffect, useImperativeHandle, forwardRef} from "react";
import { CardElement, PaymentRequestButtonElement, useStripe, useElements } from "@stripe/react-stripe-js";

import * as Cfg from '../../rdx/actions/cfg'
import './cardForm.css'
import {useDispatch, useSelector} from "react-redux";
import * as AWS from "../AWS";
import {CreateSubscription} from "../../rdx/actions";

const CardForm = forwardRef(( props,ref ) =>
{
	const orgId = useSelector(state => state.__cfg.oId)
	const dispatch = useDispatch();

	const [succeeded, setSucceeded] = useState(false);
	const [error, setError] = useState(null);
	const [processing, setProcessing] = useState('');
	const [disabled, setDisabled] = useState(true);
	// const [clientSecret, setClientSecret] = useState('');

	const stripe = useStripe();
	const [paymentRequest, setPaymentRequest] = useState(null);
	const elements = useElements();

	useImperativeHandle(ref, () => ({

		async PayWithCard(){
			return await handleSubmit()
		}

	}));


	let card = null;

	const InitPayment = async () =>
	{
		try
		{
			console.log("hello form ip")
			const pr = stripe.paymentRequest({
				country: 'GB',
				currency: 'gbp',
				total: {
					label: props.label ? props.label : '',
					amount: props.amount,
				},
				requestPayerName: true,
				requestPayerEmail: true,
			});

			const result = await pr.canMakePayment();
			console.log(result)

			if( result )
			{
				setPaymentRequest(pr);
			}
			pr.on( 'paymentmethod', OnPM );

			return {}
		}
		catch( err )
		{
			console.warn( 'CardForm: makePayment: err: ', err )

			return {err}
		}
	}	// makePayment

	const OnPM = async ( ev ) =>
	{
		try
		{
			const clientSecret = await createPaymentIntent();

			console.log( "!!!!!!!!!!!!!!!!!! ev : ", ev );

			if( ev.paymentMethod.card.country !== "GB" )
			{
				setError(`Payment failed. Only GB cards are accepted.`);
				setProcessing(false);
				return;
			}

			const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
				clientSecret,
				{payment_method: ev.paymentMethod.id},
				{handleActions: false}
			);

			if (confirmError)
			{
				ev.complete('fail');
			}
			else
			{
				ev.complete('success');
				if (paymentIntent.status === "requires_action")
				{
					// Let Stripe.js handle the rest of the payment flow.
					const {error} = await stripe.confirmCardPayment(clientSecret);
					if (error)
					{
						// The payment failed -- ask your customer for a new payment method.
					}
					else
					{
						// The payment has succeeded.
					}
				}
				else
				{

					props.onSuccess( {...props.invoice, paid_status: "paid"} );
					// The payment has succeeded.
				}
			}

			return {}
		}
		catch( err )
		{
			console.warn( 'CardForm: OnPM: err: ', err )

			return {err}
		}
	}	// OnPM

	const createPaymentIntent = async() =>
	{
		try
		{
			const aws_lambda = new AWS.Lambda( {} )

			const aws_core = new AWS.Core( {} )
			const cur_usr = await ( async () => {
				try
				{
					return await aws_core.currentuser()
				}
				catch( err )
				{
					return { attributes: {}, username: null }
				}
			} )()
			const lambda_name =  await dispatch(Cfg.Lambda('unauth'))
			console.log('lambda',lambda_name)
			const p_lambda =
				{
					actType: 'stripe',
					act: "stripe:payment-intent",
					c_id:orgId,
					alpha: {
						c_id: orgId,
					},

					data: {
						amount: props.amount,
						currency: 'GBP',
						label: props.label,
						fees:0
					},
					usr: {
						...cur_usr.attributes,
						username: cur_usr.username,
					},

					payment_type: "card",
				};

			console.log("p_lambda : ", p_lambda);
			const resp_list = await aws_lambda.Invoke( p_lambda, lambda_name )
			console.log( 'pay/cardForm: createPaymentIntent: resp_list: ', resp_list )

			return resp_list.client_secret;
		}
		catch( err )
		{
			console.error( "createPaymentIntent : catch : err : ", err );
		}
	}

	const cardStyle = {
		iconStyle: 'solid',
		style: {
			base: {
				iconColor: '#ddd',
				color: '#000',
				fontWeight: 400,
				fontFamily: 'Open Sans,Roboto, Segoe UI, sans-serif',
				fontSize: '16px',
				fontSmoothing: 'antialiased',
				margin:'16px',
				height:40,
				backgroundColor:"#F5F5F5",
				boxShadow:"1px 1px 1px #000",

				':-webkit-autofill': {
					color: '#0C4AAC',
				},
				'::placeholder': {
					color: '#707070',
				},
			},
			invalid: {
				iconColor: '#dd0000',
				color: '#dd0000',
			},
		}
	};

	const handleChange = async (event) =>
	{
		setDisabled(event.empty);
		setError(event.error ? event.error.message : "");
	};


	//
	const handleSubmit = async (event) =>
	{
		if (event)
		{
			event.preventDefault();
		}
		setProcessing(true);

		const paymentIntent = await createPaymentIntent();

		const paymentMethod = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardElement)
		});

		// console.log(paymentMethod)

		// if( paymentMethod.paymentMethod.card.country !== "GB" )
		// {
		// 	setError(`Payment failed. Only GB cards are accepted.`);
		//
		// 	setProcessing(false);
		//
		// 	return { paid_status: "not-paid", payment_success: false}
		// }

		let paymentObj = null;
		if (props.isRecurring)
		{
			const {phone,name,addr,email} = props.cus
			const cus = {
				name:[name.f,name.l].join(' '),
				email:email,
				address:{
					city:addr.c,
					country:addr.cn,
					line1:addr.l1,
					line2:addr.l2,
					postalCode:addr.pc,
					state:addr.p
				},
				phone: [phone.cn, phone.n].join('')
			}

			const p_sub = {
				phone,
				email,
				cus,
				pack:props.package,
				paymentMethod:paymentMethod.paymentMethod.id
			}

			const {resp} = await dispatch(CreateSubscription(p_sub))
			const {status,latest_invoice,id} = resp.sub
			// console.log('latest_invoice',latest_invoice)
			// console.log('val',status,latest_invoice.payment_intent.id,latest_invoice.payment_intent.status)
			if (status === 'active')
			{
				const obj = {
					s_i:id,
					pi:latest_invoice.payment_intent.id,
					st:latest_invoice.payment_intent.status
				}
				paymentObj = obj
				props.onSuccess( { paid_status: "paid", payment_success: true, paymentObj:obj} );
				return { paid_status: "paid", payment_success: true, paymentObj}

			}
			else
			{
				if (latest_invoice.payment_intent.status === 'requires_action')
				{
					const payload = await stripe.confirmCardPayment(latest_invoice.payment_intent.client_secret);
					// console.log('payload',payload)
					if (payload.error)
					{
						setError(`Payment failed. ${payload.error.message}`);
						setProcessing(false);
						return { paid_status: "not-paid", payment_success: false}

					}
					else
					{
						const obj = {
							s_i:id,
							pi:payload.paymentIntent.id,
							st:payload.paymentIntent.status
						}

						setError(null);
						setSucceeded(true);
						setProcessing(false);
						paymentObj = obj

						props.onSuccess( { paid_status: "paid", payment_success: true, paymentObj:obj} );
						return { paid_status: "paid", payment_success: true, paymentObj}

					}
				}
				else {
					setError(`Payment failed.`);
					setProcessing(false);
					return { paid_status: "not-paid", payment_success: false}
				}

			}

		}
		else {
			const payload = await stripe.confirmCardPayment(paymentIntent, {
				payment_method: paymentMethod.paymentMethod.id
			});

			if (payload.error)
			{
				setError(`Payment failed. ${payload.error.message}`);
				setProcessing(false);
				return { paid_status: "not-paid", payment_success: false}
			}
			else
			{
				setError(null);
				setSucceeded(true);
				setProcessing(false);
				const obj = {
					pi:payload.paymentIntent.id,
					st:payload.paymentIntent.status
				}
				paymentObj = obj
				props.onSuccess( { paid_status: "paid", payment_success: true, paymentObj:obj} );
				return { paid_status: "paid", payment_success: true, paymentObj}
			}
		}

		return { paid_status: "paid", payment_success: true, paymentObj}

	};


	return (
		<>
			{
				paymentRequest &&
				<div style={{width: "95%", marginRight: "auto", marginLeft: "auto", marginBottom: 16}}>
					<PaymentRequestButtonElement options={{paymentRequest}}/>
				</div>
			}

			<div>
				Card info
			</div>

			<form /* onSubmit={ handleSubmit } */>
				<div className="cardWrapper">
					<CardElement id="card-element" options={ cardStyle } onChange={ handleChange } />
				</div>
				{ error && (
					<div
						style={{color:"#c94b4b",fontSize:18,padding:8,fontWeight:600}}
						className="card-error" role="alert">
						{ error }
					</div>
				) }

			</form>

		</>

	);

});

export default CardForm;
