/**
 * KARD
 */

import React from "react";

// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";

// reactstrap components
import { Row, Col, Button } from "reactstrap";

import {connect, useDispatch} from "react-redux";
import * as actions from "rdx/actions";
import { Time } from "../../api/Utils";
import {EmailSubscribe, EmailUnsubscribe} from "../../rdx/actions/comm";

/**
 */
const ProfileSubscription = (props) => {

  const rdx = useDispatch()

  async function Sub(p) {
    props.loading(true)
    const param = {
      email:p.i,
      topic:p.tp,
      pr_t: p.pr_t,
      cp_t: p.cp_t,
    }
    await rdx(EmailSubscribe(param));
    props.reload()
  }
  async function Unsub(p) {
    props.loading(true)
    const param = {
      email:p.i,
      topic:p.tp,
    }
    await rdx(EmailUnsubscribe(param));
    props.reload()
  }

  return (
    <Col className="ml-auto mr-auto" xs="12" lg="12">
      <Row className="collections">
        <Col xs="12">
          <div className="card p-4 table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Project Name</th>
                  <th>Campaign Name</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
              {
                props?.subsList?.length>0 &&
                    props.subsList.map(val=><ListView val={val} sub={Sub} unsub={Unsub}/>)

              }
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </Col>
  );
}; // ProfileHistory

/**
 */
const ListView = (props) => {
  console.log("listView",props)
  return (
      <tr key={props.val.tp}>
        <th>{Time.Epoch2DateTime(props.val.tm)}</th>
        <th>{props.val.pr_t}</th>
        <th>{props.val.cp_t}</th>
        <th>
          <div className="btn-group">
            {
              props.val.b?
                  <Button className="btn btn-danger text-white mr-1 my-0"  onClick={()=>props.unsub(props.val)}>
                    Unsubscribe
                  </Button>
                  :
                  <Button className="btn btn-info text-white my-0" onClick={()=>props.sub(props.val)}>
                    Subscribe
                  </Button>
            }

          </div>
        </th>
      </tr>
  );
}; // ListView


/**
 */
export default ProfileSubscription;
