/**
 *	@copyright	KARD.
 */
import * as Cfg from './cfg'
import * as AWS from '../../api/AWS'
import {Core, Lambda} from "../../api/AWS";
// import { async } from 'q'

/**
 */
export const Donation_Query = ( {index, last_key, dt_st, dt_end, pr_n, cp_n} ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()
            const lambda_name = d( Cfg.Lambda('unauth') )

            const p_list = {
                actType: 'dont',
                act: 'dont:query',
                c_id:   gs().__cfg.oId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    last_key,
                    index,
                    dt_st,
                    dt_end,
                    pr_n,
                    cp_n,
                },
            }

            console.log( 'actions/donation: Donation_Query: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, lambda_name )

            // console.log( 'actions/donation: Donation_Query: resp_list: ', resp_list )

            const mapped_data = resp_list.Items.map( x => {
                try
                {
                    return {
                        ...x,
                        k: {...x.k, a: x.k.a / 100.0},
                    }
                }
                catch( err )
                {
                    return x
                }
            } )

            //
            return {...resp_list, Items: mapped_data}
        }
        catch( err )
        {
            console.warn( 'actions/donation: Donation_Query: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Donation_Query

/**
 */
export const Donation_List = ( last_key ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()
            const lambda_name = d( Cfg.Lambda('unauth') )

            const p_list = {
                actType: 'dont',
                act: 'dont:list',
                c_id:   gs().__cfg.oId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {last_key},
            }

            console.log( 'actions/donation: Donation_List: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, lambda_name )

            // console.log( 'actions/donation: Donation_List: resp_list: ', resp_list )

            const mapped_data = resp_list.Items.map( x => {
                try
                {
                    return {
                        ...x,
                        k: {...x.k, a: x.k.a / 100.0},
                    }
                }
                catch( err )
                {
                    return x
                }
            } )

            //
            return {...resp_list, Items: mapped_data}
        }
        catch( err )
        {
            console.warn( 'actions/donation: Donation_List: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Donation_List

/**
 */
export const DonationNew = ( dont_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()
            const lambda_name = d( Cfg.Lambda('unauth') )

            const p_new = {
                actType: 'dont',
                act: 'dont:new',
                c_id:  gs().__cfg.oId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    ...dont_obj,
                    pck: {
                        ...dont_obj.pck,
                        a: dont_obj.pck.a ? parseInt( dont_obj.pck.a * 100, 10 ) : 0,
                    },
                },
            }

            console.log( 'actions/donation: Donation_New: p_new: ', p_new )

            const resp_new = await aws_lambda.Invoke( p_new, lambda_name )

            // console.log( 'actions/donation: Donation_New: resp_new: ', resp_new )

            //
            return resp_new
        }
        catch( err )
        {
            console.warn( 'actions/donation: Donation_New: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Donation_New
/**
 */
export const Donation_Received = ( dont_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()
            const lambda_name = d( Cfg.Lambda('unauth') )

            const p_new = {
                actType: 'dont',
                act: 'dont:received:upd',
                c_id:   gs().__cfg.oId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username
                },

                data: {
                    ...dont_obj
                },
            }

            console.log( 'actions/donation: Donation_Received: p_upd: ', p_new )

            const resp_upd = await aws_lambda.Invoke( p_new, lambda_name )

            console.log( 'actions/donation: Donation_Received: resp_upd: ', resp_upd )

            return resp_upd
        }
        catch( err )
        {
            console.warn( 'actions/donation: Donation_Received: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Donation_Received



/**
 */
export const PaymentReceived = ( dId, payObj,status, pm, email_param ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()
            const lambda_name = d( Cfg.Lambda('unauth') )
            console.log(payObj)

            const p_new = {
                actType: 'dont',
                act: 'dont:payment:upd',
                c_id:  gs().__cfg.oId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username
                },

                data: {
                    payObj:payObj,
                    d:dId,
                    s:status,
                    m:pm,
                    email_p:email_param
                },
            }

            console.log( 'actions/donation: PaymentReceived: p_upd: ', p_new )

            // return {}
            const {donation,cp} = await aws_lambda.Invoke( p_new, lambda_name )

            console.log( 'actions/donation: PaymentReceived: resp_upd: ', donation,cp )

            const donationPaymentSuccess = donation && donation.msg === 'OK'
            const campaignEarn = cp && cp[0].msg === 'OK'
            const projectEarn = cp && cp[1].msg === 'OK'

            return {donationPaymentSuccess,campaignEarn,projectEarn}
        }
        catch( err )
        {
            console.warn( 'actions/donation: Donation_Received: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Donation_Received



/**
 */
export const Donation_Notify = ( notify_obj ) =>
{
    return async (d, gs) =>
    {
        try
        {
            await Promise.all( [
                d( _Notify_Email( notify_obj ) )
            ] )
        }
        catch( err )
        {
            console.warn( 'actions/donation: Donation_Notify: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Donation_Notify

/**
 */
const _Notify_Email = ( notify_obj ) =>
{
    return async (d, gs) =>
    {
        try
        {
            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()
            const lambda_name = d( Cfg.Lambda('unauth') )

            const p_noti = {
                actType: 'comm',
                act: 'comm:email:trn',
                c_id:   gs().__cfg.oId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {...notify_obj},
            }

            // console.log( 'actions/donation: _Notify_Email: p_noti: ', p_noti )

            const resp_noti = await aws_lambda.Invoke( p_noti, lambda_name )

            // console.log( 'actions/donation: _Notify_Email: resp_noti: ', resp_noti )

            //
            return resp_noti
        }
        catch( err )
        {
            console.warn( 'actions/donation: _Notify_Email: err: ', err )

            // return Promise.reject( err )
            return {err}
        }
    }
}   // _Notify_Email

export const CreateSubscription = (p)=>
{
    return async (d, gs)=>{
        try {
            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()
            const lambda_name = d( Cfg.Lambda('unauth') )

            const p_list = {
                actType: 'stripe',
                act: "stripe:create-subscription",
                c_id:   gs().__cfg.oId,
                data:p,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
            }

            console.log( 'actions/charity: CreateSubscription: p_list: ', p_list )
            const resp_list = await aws_lambda.Invoke( p_list, lambda_name )
            console.log( 'actions/charity: CreateSubscription: resp_list: ', resp_list )

            return {msg: "ok",resp:resp_list}
        }catch (e) {
            console.warn("action/donation: CreateSubscription:error",e)
            return Promise.reject(e)
        }
    }
}

export const Donor_New = ( donr_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()
            const lambda_name = d( Cfg.Lambda('unauth') )

            const p_new = {
                actType: 'donr',
                act: 'donr:new',
                c_id: gs().__cfg.oId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {...donr_obj},
            }

            // console.log( 'actions/project: Donor_New: p_new: ', p_new )

            const resp_new = await aws_lambda.Invoke( p_new, lambda_name )

            // console.log( 'actions/project: Donor_New: resp_new: ', resp_new )

            //
            return resp_new
        }
        catch( err )
        {
            console.warn( 'actions/project: Donor_New: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Donor_New


export const GetPubProjectList= () =>
{
    return async (d,gs)=>{
        try {
            const aws_lambda = new Lambda( {} )
            const aws_core = new Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()
            const lambda_name = d( Cfg.Lambda('unauth') )

            const p_list = {
                actType: 'project',
                act: 'project:list',
                c_id: gs().__cfg.oId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
            }

            console.log( 'action/crowed-camp GetProjectList: p_list: ', p_list )
            const resp_list = await aws_lambda.Invoke( p_list, lambda_name )
            console.log( 'action/crowed-camp GetProjectList: ', resp_list )
            if (!resp_list.success)
            {
                throw new Error("Project not found!")
            }
            return {...resp_list}
        }catch (e) {
            console.warn("action/crowed-camp: GetProject:err",e)
            return Promise.reject(e)
        }
    }
}

export const GetPubProjectCampaignList= (params) =>
{
    return async (d,gs)=>{
        try {
            const aws_lambda = new Lambda( {} )
            const aws_core = new Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()
            const lambda_name = d( Cfg.Lambda('unauth') )

            const p_list = {
                actType: 'campaign',
                act: 'campaign:project-campaign-list',
                c_id: gs().__cfg.oId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data:{
                    p_id:params.p_id
                }
            }

            console.log( 'action/crowed-camp GetProjectList: p_list: ', p_list )
            const resp_list = await aws_lambda.Invoke( p_list, lambda_name )
            console.log( 'action/crowed-camp GetProjectList: ', resp_list )
            return {success:true,...resp_list}
        }catch (e) {
            console.warn("action/crowed-camp: GetProject:err",e)
            return Promise.reject(e)
        }
    }
}
