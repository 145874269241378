import React from "react";
import "../home/style.css";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
const items = [
  {
    src: require("assets/img/slider/slider-2.png").default,
    creator: require("assets/img/m-avatar.png").default,
    title: "San Antonio, TX",
    title2: "Gabe's memorial fund",
    description: "",
    lastdonatehr: "9",
    targetamount: "1000",
    receiveamount: "220",
  },
];
function SingleCampaign() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="section section-campaign">
          <Container>
            <Row className="">
              <Col lg="9">
                {items.map((item) => {
                  return (
                    <div>
                      <img
                        alt="..."
                        className="responsive"
                        src={item.src}
                      ></img>
                      <CardBody className="pt-0 pl-0">
                        <CardTitle tag="h4" className="text-uppercase">
                          {item.title}
                        </CardTitle>
                        <CardText>
                          <p>
                            Many of us know Dugan Ashley from the days of
                            CarnikCon. Others might know him for his recent
                            reemergence to the interwebs with his cautionary
                            tales. <br /> However you've been graced with Dug's
                            presence, we can all agree that he's made a lasting
                            impact on us all. <br />
                            For those who might not know him, Dugan is a GWOT
                            Army veteran who instantly became a firearms
                            industry icon with his comical, yet informative,
                            channel, CarnikCon. <br />
                            If you've ever seen someone store Skittles in their
                            Magpul grip, you have Dugan to thank for that.
                          </p>
                          <img
                            alt="..."
                            className="rounded img-fluid img-raised"
                            src={require("assets/img/camp-s.png").default}
                          ></img>
                          <p className="mt-5">
                            Dugan has young children, and right now they are his
                            biggest concern. Though usually Dugan is the one to
                            go out of his way to help us, he has come to us
                            asking for help. He is currently undergoing
                            treatment at the VA after being hospitalized last
                            month, but is asking for any guidance or help in the
                            treatment of his MS. <br /> He's come to us to ask
                            if we can help prolong is health so that he can stay
                            with his children a little while longer. I am
                            starting this Fundraiser to help offset whatever
                            financial hardships his family is facing at this
                            time. Though Dug lives a very independent life of
                            our monetary system, unfortunately this is one of
                            the best and quickest ways to help. <br />
                            In the event that he and his family do not receive
                            this, or decide to take this fundraiser down, ALL of
                            the funds will be donated to a charity of their
                            choice. Dugan has given us all so much. <br />
                            Let's step up to the plate to help our friend.
                          </p>
                        </CardText>
                      </CardBody>
                    </div>
                  );
                })}
              </Col>
              <Col lg="3">
                {items.map((item) => {
                  return (
                    <Card>
                      <img
                        alt="..."
                        className="responsive"
                        src={item.creator}
                      ></img>
                      <CardBody>
                        <CardTitle tag="h5">Created By: Jon Doe</CardTitle>
                        <CardText>{item.description}</CardText>
                        <p>Last donate {item.lastdonatehr} hr ago</p>
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: "70%" }}
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <CardTitle tag="h3" className="font-weight-bold">
                          {item.receiveamount}$ Raised{" "}
                          <span className="text-muted">
                            of {item.targetamount}$
                          </span>
                        </CardTitle>
                      </CardBody>
                    </Card>
                  );
                })}

                {items.map((item) => {
                  return (
                    <Card>
                      <CardBody>
                        <CardTitle tag="h5">Recent Donator</CardTitle>
                        <CardText>{item.description}</CardText>
                        <div>
                          <p className="font-weigh-bold text-green">
                            Sarwar Hossain $ 50{" "}
                          </p>
                          <p className="font-weigh-bold text-green">
                            Arif Hossain $ 50{" "}
                          </p>
                          <p className="font-weigh-bold text-green">
                            Ryan Hossain $ 50{" "}
                          </p>
                          <p className="font-weigh-bold text-green">
                            Asif Hossain $ 50{" "}
                          </p>
                          <p className="font-weigh-bold text-green">
                            Koushik Hossain $ 50{" "}
                          </p>
                        </div>
                      </CardBody>
                    </Card>
                  );
                })}
                <a href="/donation" class="btn btn-lg btn-block btn-green">
                  Donate Now
                </a>
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default SingleCampaign;
