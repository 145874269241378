/**
 * @copyright   KARD
 */

// import {AsyncStorage} from 'react-native'

// import DeviceInfo from 'react-native-device-info';
// import elml_config from '../_cfg/app';

// import cfg_core from "../_cfg/_core";

/**
 */
class Addr
{
    /**
     */
    static Compare( addr_1, addr_2 )
    {
        // const tmp_1 = Addr.Prep( {...addr_1} );
        // const tmp_2 = Addr.Prep( {...addr_2} );

        // console.log( 'Addr: Compare: addr_1: ', addr_1 );
        // console.log( 'Addr: Compare: addr_2: ', addr_2 );

        let tmp_1 = {...addr_1};
        let tmp_2 = {...addr_2};

        tmp_1 = Object.keys( tmp_1 ).reduce( (acc, cur) => {
            acc[cur] = acc[cur].replace( / /g, '' ).replace(/[^\w\s]/gi, '').toLowerCase();
            return acc;
        }, tmp_1 );

        tmp_2 = Object.keys( tmp_2 ).reduce( (acc, cur) => {
            acc[cur] = acc[cur].replace( / /g, '' ).replace(/[^\w\s]/gi, '').toLowerCase();
            return acc;
        }, tmp_2 );

        // console.log( 'Addr: Compare: tmp_1: ', tmp_1 );
        // console.log( 'Addr: Compare: tmp_2: ', tmp_2 );

        if( tmp_1.line1 !== tmp_2.line1 )
            return false;

        if( tmp_1.line2 !== tmp_2.line2 )
            return false;

        if( tmp_1.city !== tmp_2.city )
            return false;

        if( tmp_1.county !== tmp_2.county )
            return false;

        if( tmp_1.country !== tmp_2.country )
            return false;

        if( tmp_1.postcode !== tmp_2.postcode )
            return false;

        return true;
    }

    /**
     */
    static Prep( addr )
    {
        addr = Object.keys( addr ).reduce( (acc, cur) => {
            acc[cur] = acc[cur].trim();
            return acc;
        }, addr );

        //
        addr.postcode = addr.postcode.replace( / /g, '' );

        //
        return addr;
    }

    // /**
    //  */
    // static Spacify( addr )
    // {
    //     return Object.keys( addr ).reduce( (acc, cur) => {
    //         acc[cur] = Utils.Spacify( acc[cur] );
    //         return acc;
    //     }, addr );
    // }   // Spacify

    /**
     */
    static Stringify( addr, type )
    {
        if( !type )
            type = 'short1';

        if( 'short1' === type )
            return [addr.line1, addr.line2, addr.city, addr.postcode].join();
        else if( 'short2' === type )
            return [addr.line1, addr.line2, addr.city, addr.county, addr.postcode].join();
        else if( 'full' === type )
            return [addr.line1, addr.line2, addr.city, addr.county, addr.postcode, addr.country].join();
        else
            return null;
    }   // Stringify

    // /**
    //  */
    // static Parse( addr_str )
    // {
    //     let addr_obj = Utils.Parse( addr_str );

    //     if( !addr_obj )
    //         return addr_obj;

    //     Object.keys( addr_obj ).reduce( (acc, cur) => {
    //         //
    //     }, addr_obj );
    // }   // Parse
}

/**
 */
class Time
{
    /**
     */
    static Epoch( src_time )
    {
        if (src_time && typeof src_time === "number" && src_time.toString().length<13)
            src_time = src_time*1000

        // console.log(src_time)
        const dt = src_time ? new Date( src_time ) : new Date(Date.now());
        const dt_ms = dt ? dt.getTime() : Date.now();
        return parseInt( dt_ms / 1000 );
    }   // Epoch
    /**
     */
    static Datetime( src_time )
    {
        return  src_time * 1000 ;
    }   // Epoch

    /**
     */
    static EpochStart( src_time )
    {
        // let start = new Date();
        // start.setHours(0,0,0,0);

        const dt = src_time ? new Date( src_time ) : new Date();
        console.log("EpochStart",dt,src_time);
        const dt_ms = dt ? dt.setHours(0,0,0,0) : Date.now();

        return parseInt( dt_ms / 1000 );
    }   // Epoch
    /**
     */
    static EpochEnd( src_time )
    {
        const dt = src_time ? new Date( src_time ) : new Date();
        const dt_ms = dt ? dt.setHours(23,59,59,999) : Date.now();

        return parseInt( dt_ms / 1000 );
    }   // Epoch


    /**
     */
    static Print2D( src_number )
    {
        return src_number.toString().padStart( 2, '0' )
    }
    /**
     */
    static EpochZeroSec( src_number )
    {
        const time = (this.Epoch(src_number).toString()).slice(0,-2);
        // // time.;
        // console.log(this.Epoch(src_number).toString());
        // console.log(time);
        // const fEpoc = time.padEnd(2, '0');

        return parseInt(time+"00");
    }
    /**
     */
    static MMYYYY( src )
    {
        if (!src)
            return ''

        const M =this.monthsFull[new Date(src).getMonth()]
        const Y =new Date(src).getFullYear()

        return [M,Y].join('-')
    }
    static DTObj (val)
    {
        const dd = val ? new Date(this.Epoch2Time(val)).getDate() : new Date().getDate();
        const mm = val ? new Date(this.Epoch2Time(val)).getMonth()+1 : new Date().getMonth()+1;
        const yyyy = val ? new Date(this.Epoch2Time(val)).getFullYear() : new Date().getFullYear();
        const HH = val ? new Date(this.Epoch2Time(val)).getHours() : new Date().getHours();
        const MM = val ? new Date(this.Epoch2Time(val)).getMinutes() : new Date().getMinutes();
        return {dd:this.Print2D(dd),mm:this.Print2D(mm),yyyy,HH:this.Print2D(HH),MM:this.Print2D(MM)}
    }
    /**
     */
    static Epoch2DateTime( val )
    {
        const {dd,mm,yyyy,HH,MM} = this.DTObj(val)

        return [dd,mm,yyyy].join('/')/*+" "+[HH,MM].join(':')*/
    }

    /**
     */
    static FormatArray2Time (val)
    {
        if (!val.length)
            return Date.now()
        if (val.length<3)
            return new Date().setHours(val[0],val[1],0);
        else {
            // console.log(val[2])
            const h = val[2] ===  'pm' ? parseInt(val[0])+12 : parseInt(val[0])
            return new Date().setHours(h,val[1],0,0);
        }
    }//Format12H2Time
    /**
     */
    static FormatTime2Array (val)
    {
        const {HH, MM} = this.DTObj(this.Epoch(val))
        if (HH>12)
            return [(HH-12),MM,"PM"]
        else
            return [HH,MM,"AM"]
    }//Format12H2Time
    /**
     */
    static TimeTo12h (val)
    {
        const {HH, MM} = this.DTObj(this.Epoch(val))
        if (HH>12)
            return [(HH-12),MM].join(":")+"PM"
        else
            return [HH,MM].join(":")+"AM"
    }//Format12H2Time

    /**
     */
    static Epoch2Date( val )
    {
        const {dd,mm,yyyy} = this.DTObj(val)

        return [dd,mm,yyyy].join('/')
    }/**
     */
    static Epoch2DateYMD( val )
    {
        // console.log(new Date(this.Epoch2Time(val)))
        // console.log(new Date(this.Epoch2Time(val)).getDate())

        const {dd,mm,yyyy} = this.DTObj(val)

        return [yyyy,mm,dd].join('-')
    }
    /**
     */
    static Epoch2HM( val )
    {

        const hh = val ? new Date(this.Epoch2Time(val)).getHours() : new Date().getHours();
        const mm = val ? new Date(this.Epoch2Time(val)).getMinutes() : new Date().getMinutes();

        return [this.Print2D(hh),this.Print2D(mm)].join(':')
    }

    /**
     */
    static Epoch2Time( epc_time )
    {
        if (epc_time.toString().length>11)
            return epc_time
        return epc_time * 1000
    }   // Epoch
    static PrintTime( dt_obj, p )
    {
        return [ dt_obj.getHours(), dt_obj.getMinutes() ].join( p && p.sep ? p.sep : ' : ' )
    }

    static  Epoch2HMObject(val)
    {
        const h = val ? new Date(this.Epoch2Time(val)).getHours() : new Date().getHours();
        const m = val ? new Date(this.Epoch2Time(val)).getMinutes() : new Date().getMinutes();
        return {h,m}
    }

    static  HMObject2Epoch(obj)
    {
        const dt = obj ? new Date().setHours(obj.h?obj.h:0,obj.m?obj.m:0,0) : new Date().setHours(0,0,0);
        return this.Epoch(dt)
    }

    /**
     */
    static dayShort = {
        0: 'Sun'
        ,   1: 'Mon'
        ,   2: 'Tue'
        ,   3: 'Wed'
        ,   4: 'Thu'
        ,   5: 'Fri'
        ,   6: 'Sat'
    }
    /**
     */
    static dayFull = {
        0: 'Sunday'
        ,   1: 'Monday'
        ,   2: 'Tuesday'
        ,   3: 'Wednesday'
        ,   4: 'Thursday'
        ,   5: 'Friday'
        ,   6: 'Saturday'
    }
    /**
     */
    static monthsShort = {
        0: 'Jan'
        ,   1: 'Feb'
        ,   2: 'Mar'
        ,   3: 'Apr'
        ,   4: 'May'
        ,   5: 'Jun'
        ,   6: 'Jul'
        ,   7: 'Aug'
        ,   8: 'Sep'
        ,   9: 'Oct'
        ,   10: 'Nov'
        ,   11: 'Dec'
    }

    /**
     */
    static monthsFull = {
        0: 'January'
        ,   1: 'February'
        ,   2: 'March'
        ,   3: 'Aprril'
        ,   4: 'May'
        ,   5: 'June'
        ,   6: 'July'
        ,   7: 'August'
        ,   8: 'September'
        ,   9: 'October'
        ,   10: 'November'
        ,   11: 'December'
    }
}   // class Time

/**
 */
class Utils
{
    //
    // static Decode2Base64( dataString )
    // {
    //     var matches = dataString.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/),
    //         response = {};

    //     if (matches.length !== 3) {
    //         return new Error('Invalid input string');
    //     }

    //     response.type = matches[1];
    //     response.data = new Buffer(matches[2], 'base64');

    //     return response;
    // }

    /**
     */
    // static IpAddr()
    // {
    //     return new Promise( (sol, rej) => {
    //         DeviceInfo.getIPAddress()
    //             .then( data => sol(data) )
    //             .catch( err => rej(null) )
    //     } )
    // }   // IpAddr


    static Spacify( src )
    {
        // return str ? str : ' ';
        if( 'string' === typeof src )
        {
            return src ? src : ' ';
        }
        else if( 'object' === typeof src )
        {
            if( Array.isArray(src) )
            {
                return src.map( x => Utils.Spacify( x ) );
            }
            else
            {
                // for objects
                return Object.keys( src ).reduce( (acc, cur) => {
                    acc[cur] = Utils.Spacify( acc[cur] );
                    return acc;
                }, src );
            }
        }
        else
            return src;
    }

    /**
     */
    static CopyDeep( src_obj )
    {
        try{
            return JSON.parse( JSON.stringify( src_obj ) );
        }
        catch( err ){
            console.warn( 'Utils: CopyDeep: err: ', err );
            return null;
        }
    }   // CopyDeep

    // /**
    //  */
    // static DeviceId()
    // {
    //     return this.beta_user_id = [
    //         'device'
    //         ,   DeviceInfo.getSystemName().toLowerCase()
    //         ,   DeviceInfo.getUniqueID().toLowerCase()
    //         ,   DeviceInfo.getSerialNumber()
    //     ].join(':');
    // }   // DevId

    /**
     */
    static Price( val )
    {
        try{
            return val.toFixed( 2 );
        }
        catch( err ){
            return val;
        }
    }

    /**
     */
    static Parse( src )
    {
        try{
            return JSON.parse( src );
        }
        catch( err ){
            // console.error("Parse error : ", err);
            return null;
        }
    }   // static Parse

    static Stringify( obj )
    {
        try{
            return JSON.stringify( obj );
        }
        catch( err ){
            return obj;
        }
    }

    /**
     */
    static ParseString( src )
    {
        return 'string' === typeof src ? Utils.Parse(src) : src;
    }

    /**
     */
    static _IsNA( obj )
    {
        if( typeof undefined === typeof obj || !obj )
            return true;
        return false;
    }   // function _IsNA

    /**
     */
    static Tbl( table_name, stage )
    {
        if( !this._IsNA(stage) && 'test' === stage )
            return table_name = 'test_' + table_name;

        return table_name;
    }

    static MD5 = (string) => {
        function RotateLeft(lValue, iShiftBits) {
            return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
        }

        function AddUnsigned(lX, lY) {
            var lX4, lY4, lX8, lY8, lResult;
            lX8 = (lX & 0x80000000);
            lY8 = (lY & 0x80000000);
            lX4 = (lX & 0x40000000);
            lY4 = (lY & 0x40000000);
            lResult = (lX & 0x3FFFFFFF) + (lY & 0x3FFFFFFF);
            if (lX4 & lY4) {
                return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
            }
            if (lX4 | lY4) {
                if (lResult & 0x40000000) {
                    return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
                } else {
                    return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
                }
            } else {
                return (lResult ^ lX8 ^ lY8);
            }
        }

        function F(x, y, z) {
            return (x & y) | ((~x) & z);
        }

        function G(x, y, z) {
            return (x & z) | (y & (~z));
        }

        function H(x, y, z) {
            return (x ^ y ^ z);
        }

        function I(x, y, z) {
            return (y ^ (x | (~z)));
        }

        function FF(a, b, c, d, x, s, ac) {
            a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
            return AddUnsigned(RotateLeft(a, s), b);
        };

        function GG(a, b, c, d, x, s, ac) {
            a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
            return AddUnsigned(RotateLeft(a, s), b);
        };

        function HH(a, b, c, d, x, s, ac) {
            a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
            return AddUnsigned(RotateLeft(a, s), b);
        };

        function II(a, b, c, d, x, s, ac) {
            a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
            return AddUnsigned(RotateLeft(a, s), b);
        };

        function ConvertToWordArray(string) {
            var lWordCount;
            var lMessageLength = string.length;
            var lNumberOfWords_temp1 = lMessageLength + 8;
            var lNumberOfWords_temp2 = (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64;
            var lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16;
            var lWordArray = Array(lNumberOfWords - 1);
            var lBytePosition = 0;
            var lByteCount = 0;
            while (lByteCount < lMessageLength) {
                lWordCount = (lByteCount - (lByteCount % 4)) / 4;
                lBytePosition = (lByteCount % 4) * 8;
                lWordArray[lWordCount] = (lWordArray[lWordCount] | (string.charCodeAt(lByteCount) << lBytePosition));
                lByteCount++;
            }
            lWordCount = (lByteCount - (lByteCount % 4)) / 4;
            lBytePosition = (lByteCount % 4) * 8;
            lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
            lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
            lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
            return lWordArray;
        };

        function WordToHex(lValue) {
            var WordToHexValue = "", WordToHexValue_temp = "", lByte, lCount;
            for (lCount = 0; lCount <= 3; lCount++) {
                lByte = (lValue >>> (lCount * 8)) & 255;
                WordToHexValue_temp = "0" + lByte.toString(16);
                WordToHexValue = WordToHexValue + WordToHexValue_temp.substr(WordToHexValue_temp.length - 2, 2);
            }
            return WordToHexValue;
        };

        function Utf8Encode(string) {
            string = string.replace(/\r\n/g, "\n");
            var utftext = "";

            for (var n = 0; n < string.length; n++) {

                var c = string.charCodeAt(n);

                if (c < 128) {
                    utftext += String.fromCharCode(c);
                }
                else if ((c > 127) && (c < 2048)) {
                    utftext += String.fromCharCode((c >> 6) | 192);
                    utftext += String.fromCharCode((c & 63) | 128);
                }
                else {
                    utftext += String.fromCharCode((c >> 12) | 224);
                    utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                    utftext += String.fromCharCode((c & 63) | 128);
                }

            }

            return utftext;
        };

        var x = Array();
        var k, AA, BB, CC, DD, a, b, c, d;
        var S11 = 7, S12 = 12, S13 = 17, S14 = 22;
        var S21 = 5, S22 = 9, S23 = 14, S24 = 20;
        var S31 = 4, S32 = 11, S33 = 16, S34 = 23;
        var S41 = 6, S42 = 10, S43 = 15, S44 = 21;

        string = Utf8Encode(string);

        x = ConvertToWordArray(string);

        a = 0x67452301;
        b = 0xEFCDAB89;
        c = 0x98BADCFE;
        d = 0x10325476;

        for (k = 0; k < x.length; k += 16) {
            AA = a;
            BB = b;
            CC = c;
            DD = d;
            a = FF(a, b, c, d, x[k + 0], S11, 0xD76AA478);
            d = FF(d, a, b, c, x[k + 1], S12, 0xE8C7B756);
            c = FF(c, d, a, b, x[k + 2], S13, 0x242070DB);
            b = FF(b, c, d, a, x[k + 3], S14, 0xC1BDCEEE);
            a = FF(a, b, c, d, x[k + 4], S11, 0xF57C0FAF);
            d = FF(d, a, b, c, x[k + 5], S12, 0x4787C62A);
            c = FF(c, d, a, b, x[k + 6], S13, 0xA8304613);
            b = FF(b, c, d, a, x[k + 7], S14, 0xFD469501);
            a = FF(a, b, c, d, x[k + 8], S11, 0x698098D8);
            d = FF(d, a, b, c, x[k + 9], S12, 0x8B44F7AF);
            c = FF(c, d, a, b, x[k + 10], S13, 0xFFFF5BB1);
            b = FF(b, c, d, a, x[k + 11], S14, 0x895CD7BE);
            a = FF(a, b, c, d, x[k + 12], S11, 0x6B901122);
            d = FF(d, a, b, c, x[k + 13], S12, 0xFD987193);
            c = FF(c, d, a, b, x[k + 14], S13, 0xA679438E);
            b = FF(b, c, d, a, x[k + 15], S14, 0x49B40821);
            a = GG(a, b, c, d, x[k + 1], S21, 0xF61E2562);
            d = GG(d, a, b, c, x[k + 6], S22, 0xC040B340);
            c = GG(c, d, a, b, x[k + 11], S23, 0x265E5A51);
            b = GG(b, c, d, a, x[k + 0], S24, 0xE9B6C7AA);
            a = GG(a, b, c, d, x[k + 5], S21, 0xD62F105D);
            d = GG(d, a, b, c, x[k + 10], S22, 0x2441453);
            c = GG(c, d, a, b, x[k + 15], S23, 0xD8A1E681);
            b = GG(b, c, d, a, x[k + 4], S24, 0xE7D3FBC8);
            a = GG(a, b, c, d, x[k + 9], S21, 0x21E1CDE6);
            d = GG(d, a, b, c, x[k + 14], S22, 0xC33707D6);
            c = GG(c, d, a, b, x[k + 3], S23, 0xF4D50D87);
            b = GG(b, c, d, a, x[k + 8], S24, 0x455A14ED);
            a = GG(a, b, c, d, x[k + 13], S21, 0xA9E3E905);
            d = GG(d, a, b, c, x[k + 2], S22, 0xFCEFA3F8);
            c = GG(c, d, a, b, x[k + 7], S23, 0x676F02D9);
            b = GG(b, c, d, a, x[k + 12], S24, 0x8D2A4C8A);
            a = HH(a, b, c, d, x[k + 5], S31, 0xFFFA3942);
            d = HH(d, a, b, c, x[k + 8], S32, 0x8771F681);
            c = HH(c, d, a, b, x[k + 11], S33, 0x6D9D6122);
            b = HH(b, c, d, a, x[k + 14], S34, 0xFDE5380C);
            a = HH(a, b, c, d, x[k + 1], S31, 0xA4BEEA44);
            d = HH(d, a, b, c, x[k + 4], S32, 0x4BDECFA9);
            c = HH(c, d, a, b, x[k + 7], S33, 0xF6BB4B60);
            b = HH(b, c, d, a, x[k + 10], S34, 0xBEBFBC70);
            a = HH(a, b, c, d, x[k + 13], S31, 0x289B7EC6);
            d = HH(d, a, b, c, x[k + 0], S32, 0xEAA127FA);
            c = HH(c, d, a, b, x[k + 3], S33, 0xD4EF3085);
            b = HH(b, c, d, a, x[k + 6], S34, 0x4881D05);
            a = HH(a, b, c, d, x[k + 9], S31, 0xD9D4D039);
            d = HH(d, a, b, c, x[k + 12], S32, 0xE6DB99E5);
            c = HH(c, d, a, b, x[k + 15], S33, 0x1FA27CF8);
            b = HH(b, c, d, a, x[k + 2], S34, 0xC4AC5665);
            a = II(a, b, c, d, x[k + 0], S41, 0xF4292244);
            d = II(d, a, b, c, x[k + 7], S42, 0x432AFF97);
            c = II(c, d, a, b, x[k + 14], S43, 0xAB9423A7);
            b = II(b, c, d, a, x[k + 5], S44, 0xFC93A039);
            a = II(a, b, c, d, x[k + 12], S41, 0x655B59C3);
            d = II(d, a, b, c, x[k + 3], S42, 0x8F0CCC92);
            c = II(c, d, a, b, x[k + 10], S43, 0xFFEFF47D);
            b = II(b, c, d, a, x[k + 1], S44, 0x85845DD1);
            a = II(a, b, c, d, x[k + 8], S41, 0x6FA87E4F);
            d = II(d, a, b, c, x[k + 15], S42, 0xFE2CE6E0);
            c = II(c, d, a, b, x[k + 6], S43, 0xA3014314);
            b = II(b, c, d, a, x[k + 13], S44, 0x4E0811A1);
            a = II(a, b, c, d, x[k + 4], S41, 0xF7537E82);
            d = II(d, a, b, c, x[k + 11], S42, 0xBD3AF235);
            c = II(c, d, a, b, x[k + 2], S43, 0x2AD7D2BB);
            b = II(b, c, d, a, x[k + 9], S44, 0xEB86D391);
            a = AddUnsigned(a, AA);
            b = AddUnsigned(b, BB);
            c = AddUnsigned(c, CC);
            d = AddUnsigned(d, DD);
        }

        var temp = WordToHex(a) + WordToHex(b) + WordToHex(c) + WordToHex(d);

        return temp.toLowerCase();
    }   // MD5

    static Beta_Act = fn_name => {
        return this._IsShared() ? fn_name + '_shared' : fn_name;
    };
    // static _IsShared = () => {
    //     if (!this._IsNA(elml_config.elmelo_b_shared) && elml_config.elmelo_b_shared)
    //         return true;
    //     return false;
    // };   // function _IsShared

    // static _Is2s = () => {
    //     if (!this._IsNA(elmelo_b_2s) && elmelo_b_2s)
    //         return true;
    //     return false;
    // };   // function _IsShared
    static _IsNA = obj => {
        if (typeof undefined === typeof obj || !obj)
            return true;
        return false;
    };   // function _IsNA

    // static _PhoneNumber = (p) =>{
    //     const phone =  p.phone.replace(/\s+/g, '').replace(/^(0{1,})/, '');
    //     const cnCode = p.cnCode? p.cnCode : cfg_core.cnCode
    //     return [cnCode,phone].join('')
    // }
}   // class Utils

/**
 */
class AStorage
{
    static async Get( key )
    {
        try{
            // const data = await AsyncStorage.getItem( key );
            const data = localStorage.getItem( key );

            return Common.Parse( data );
        }
        catch(err){
            throw err;
        }
    }

    static async Set( key, val )
    {
        try{
            // await AsyncStorage.setItem( key, Common.Stringify( val ) );
            localStorage.setItem( key, Common.Stringify( val ) );

            return {msg: 'OK'};
        }
        catch( err ){
            throw err;
        }
    }
    static async RemoveItem( key )
    {
        try{
            // await AsyncStorage.removeItem(key);
            localStorage.removeItem(key);

            return {msg: 'OK'};
        }
        catch( err ){
            throw err;
        }
    }

    static MD5 = Utils.MD5;
}   // class AStorage

/**
 */
class Common
{
    static Parse( str )
    {
        try{
            return JSON.parse( str );
        }
        catch( err ){
            return str;
        }
    }

    static Stringify( obj )
    {
        try{
            return JSON.stringify( obj );
        }
        catch( err ){
            return obj;
        }
    }

    /**
     */
    static _IsNA( obj )
    {
        if( typeof undefined === typeof obj || !obj )
            return true;
        return false;
    }   // function _IsNA

    /**
     */
    static Tbl( table_name, stage )
    {
        if( !this._IsNA(stage) && 'test' === stage )
            return table_name = 'test_' + table_name;

        return table_name;
    }

    /**
     */
    static Capitalize(string)
    {
        if (typeof string !== 'string')
            return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    /**
     */
    static _Split(string,index)
    {

        if (typeof string !== 'string')
            return 'Type Error';
        if (index)
        return string.split('_')[index];

        return this.Capitalize(string.split('_').join(' '))

    }



}   // class Common

class UI
{
    static Name (name) {
        if (typeof name === 'string')
            return name
        else
            return [name.title , name.first ,name.last].join(' ').trim()
    }
    static Address (addr) {
        if (typeof addr === 'string')
            return addr
        else
            return [...addr.line , addr.city ,addr.county].join(' ').trim()
    }
    static Phone (obj) {
        if (!obj)
            return ''
        else
            return obj.contactInfo.phone.length>0 ? [obj.contactInfo.phone].join(',') : ''
    }
    static Email (obj) {
        if (!obj)
            return ''
        else
            return obj.contactInfo.email.length>0 ? [obj.contactInfo.email].join(',') : ''
    }
    static Achievement (data) {
        if (!data)
            return []
        else
            return data.length>0 ? data.reduce((acc,curr)=>{
                if (curr.dtAchiev)
                    acc.push([curr.name,curr.dtAchiev].join(' - '))
                else
                    acc.push(curr.name)
                return acc;
            },[]) : []
    }
    static Specialities (data) {
        if (!data)
            return []
        else
            return data.length>0 ? data.join() : []
    }
    static Career (data) {
        if (!data)
            return []
        else
            return data.length>0 ? data.reduce((acc,curr)=>{
                acc.push([curr.name,curr.year].join(' - '))
                return acc;
            },[]) : []
    }
    static Age (data) {
        if (!data)
            return 'invalid date'
        // console.log(new Date(Time.Epoch2Time(parseInt(data))).getTime())
        // console.log("dt_now",Date.now())
        const ageDifMs = Date.now() - new Date(Time.Epoch2Time(parseInt(data))).getTime();
        const ageDate = new Date(ageDifMs); // miliseconds from epoch
        // console.log(ageDifMs,ageDate)
        return (ageDate.getFullYear()-1970).toString()
    }
    static DoctorImg (data) {
        const demoImg = "../../demoDoc.jpg"
        if (!data)
            return demoImg
        return  data.profileImgUrl.thumb && data.profileImgUrl.thumb.length>3 ? data.profileImgUrl.thumb : data.profileImgUrl.full && data.profileImgUrl.full.length>3 ? data.profileImgUrl.full : demoImg ;

    }
}


export  {Addr, Common, AStorage, Utils, Time, UI};
