

/**
 */
export const stripe = {
    key: {
        live: 'pk_live_CsZHj0WJH3LXOllagfsTQVJO',
        test: 'pk_test_g9kLXsq7bwsQjllLFWMpJqEp',
        // live: 'pk_live_51KEd2dHCE5ChRPo3kGTNInsw3od0JjqU6xChrZEmRIenLaP257ZhjAgtu7NH0Pv2Dlr0rSxesyiBZm06YJp2Dz3u00Wx9H2Pk4',
        // test: 'pk_test_51JyfsdKdbv2X8jmA4tCsVHzoiEDv0ankfzEyWwXq5oQhfJvosFp3BBf9YiSOMTTtD3ju7LG8QCOJbor8vIbD417t00Gves5QBP', //old
        // test: 'pk_test_51KEd2dHCE5ChRPo3CNOKIsGKrXCgWQir68aTLDCgw5DWYLVPXJVVbR49LKK5YxHmtwBfxhZ4l7xroLfu2NK4pXuE00NcuAgjHT',
    },
    v: '2019-12-03',
}


