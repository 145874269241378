import React from "react";
import "./style.css";
// reactstrap components

import { Container, Col, Row } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import NarrowFooter from "components/Footers/NarrowFooter.js";

function BuildingTubewell() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "50vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/slider/tubewell.jpg").default +
                ")",
            }}
          ></div>
          <div
            className="content-center"
            style={{ backgroundColor: "rgba(0,0,0,.3)" }}
          >
            <Container>
              <h3
                className="text-white h3"
                style={{ fontSize: 46, fontWeight: 700 }}
              >
                Tubewell
              </h3>
              <p className="subHead">
                ‘The Messenger of Allah (saw) was asked, “Which charity is
                best?” He replied, “[Providing] water”’. [Abu Dawud]
              </p>
            </Container>
          </div>
        </div>
        <div className="section">
          <Container>
            <Row className="no-gutters">
              <Col sm="6">
                <div className="greenBox">
                  <p className="gsubtitle">TAKE IN</p>

                  <h2 className="gboxTitle">01. The Need</h2>

                  <p className="gboxDetails">
                    The mosque plays a vital role in bringing the community
                    together and uplifting their spirits. Help us to build a
                    stunning Masjid and unite these poverty-stricken
                    communities. Islamic history shows us that the Mosque has
                    long been considered the focal point of any community. It is
                    a place of social interaction, education and religious
                    practice and has an instrumental role in bringing attention
                    to the well-being and welfare of its members.
                  </p>
                </div>
              </Col>
              <Col sm="6">
                <div className="whiteBox">
                  <p className="gsubtitle">TAKE IN</p>

                  <h2 className="gboxTitle">02. The Need</h2>

                  <p className="gboxDetails">
                    The mosque plays a vital role in bringing the community
                    together and uplifting their spirits. Help us to build a
                    stunning Masjid and unite these poverty-stricken
                    communities. Islamic history shows us that the Mosque has
                    long been considered the focal point of any community. It is
                    a place of social interaction, education and religious
                    practice and has an instrumental role in bringing attention
                    to the well-being and welfare of its members.
                  </p>
                </div>
              </Col>
              <Col sm="6">
                <div className="whiteBox">
                  <p className="gsubtitle">LEARN MORE</p>

                  <h2 className="gboxTitle">03. How did we do it</h2>

                  <p className="gboxDetails">
                    With your donation we build permanent structures of the
                    mosque for the entire community to use. Just £20 provides a
                    prayer area for a Masjid, whilst £5000 can help us to build
                    a small Masjid in Somalia
                  </p>
                </div>
              </Col>
              <Col sm="6">
                <div className="greyBox">
                  <p className="gsubtitle">SPEAK UP</p>

                  <h2 className="gboxTitle">04. The difference made</h2>

                  <p className="gboxDetails">
                    Mosque is the most frequented place for a Muslim in many
                    Muslim countries, with the available resources a Masjid can
                    creates a stronger community and provides numerous community
                    level services. Mosques can often be regarded as the HUB for
                    a community.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <NarrowFooter />
      </div>
    </>
  );
}

export default BuildingTubewell;
