/*eslint-disable*/
import React from "react";
import logo from "assets/img/ob-logo.png";
// reactstrap components
import { Container, Row } from "reactstrap";

function CharityFooter() {
  return (
    <footer
      className="footer footer-charity"
      style={{ backgroundColor: "rgb(74 77 81)" }}
    >
      <Container>
        <Row>
          <div className="col-sm-3">
            <div className="footer-logo mb-3">
              <img src={logo} alt="Logo" style={{ maxWidth: "80%" }} />
            </div>
            <p className="" style={{ fontSize: "16px", color: "#000" }}>
              Our mission is to implement sustainable interventions that enhance
              the quality of life for under-served persons.
            </p>
            <div className="social-icon">
              <a
                href="https://www.facebook.com/oblbf/"
                target="_blank"
                class="btn-neutral btn-icon btn btn-facebook btn-lg"
              >
                <i class="fab fa-facebook-square"></i>
              </a>
              <a
                href="https://twitter.com/OBLBF"
                target="_blank"
                class="btn-neutral btn-icon btn btn-twitter btn-lg"
              >
                <i class="fab fa-twitter-square"></i>
              </a>
              <a
                href="https://www.instagram.com/oblbf_/"
                target="_blank"
                class="btn-neutral btn-icon btn btn-instagram btn-lg"
              >
                <i class="fab fa-instagram"></i>
              </a>
            </div>
          </div>

          <div className="col-sm-2">
            <h4>Quick Links</h4>
            <ul className="list-unstyled">
              <li>
                <a href="/home">Home</a>
              </li>
              <li>
                <a href="/about/us">What we do</a>
              </li>
              <li>
                <a href="/work">Works</a>
              </li>
              <li>
                <a href="#">Donation</a>
              </li>
            </ul>
          </div>

          <div className="col-sm-3">
            <h4>Recent Programs</h4>
            <ul className="list-unstyled">
              <li>
                <a href="/work/covid-19">COVID 19</a>
              </li>
              <li>
                <a href="/work/portable-water">Drink Clean Water</a>
              </li>
              <li>
                <a href="/work/health-awareness">Health Awareness</a>
              </li>
              <li>
                <a href="/work/education-scholarship">Free Education</a>
              </li>
            </ul>
          </div>
          <div className="col-sm-4">
            <h4>Contact</h4>
            <div className="address mb-3 mt-4">
              <span className="fa fa-map-marker pr-2" /> 17/23 Igbodo Street Old
              G.R.A, Port-Harcourt, Rivers State.
            </div>
            <div className="mail mb-3">
              <span className="fa fa-envelope pr-2" />
              info@oblulubriggsfoundation.org
            </div>
            <div className="phone pr-2">
              <span className="fa fa-phone" /> +23484847781 +234706 855 5089
            </div>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default CharityFooter;
