/**
 *	@copyright	KARD..
 */

import React from "react";
import { navigate, Link } from "@reach/router";

// reactstrap components
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

import { connect } from "react-redux";
import * as actions from "rdx/actions";

import Cfg from "_cfg/core";

import logo from "assets/img/logo.jpg";
import "./navbar.css";

/**
 */
const IndexNavbar = (props) => {
  const [navbarColor, setNavbarColor] = React.useState("bg-white");
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  /**
   */
  React.useEffect(() => {
    // console.log("IndexNavbar: props: ", props);

    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("bg-white");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, []);

  /**
   */
  // React.useEffect( () => {
  //   console.log("IndexNavbar: props: ", props);
  // }, [props.__auth.status] )

  /**
   */
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <div id="header-top" className="fixed-top">
        <Container>
          <div className="social-info">
            <ul className="header-social">
              <li>
                <span>
                  <i className="fa fa-phone" /> 02035152764
                </span>
              </li>
              <li>
                <span>
                  <i className="fa fa-envelope" /> info@littlehope.org.uk
                </span>
              </li>
              <li>
                <span>
                  <i className="fab fa-facebook-f" aria-hidden="true"></i>
                </span>
              </li>
              <li>
                <span>
                  <i className="fa fa-rss" />
                </span>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Navbar expand="lg" color="white" className="fixed-top">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand href="#" target="_blank" id="navbar-brand">
              <a href="">
                <img src={logo} alt="Logo" style={{ width: "42px" }} />
              </a>
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink href="/home">Home</NavLink>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  href="/appeals"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  Appeals
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem href="/eyecamp">
                    Eye Camp in Bangladesh
                  </DropdownItem>
                  <DropdownItem href="/masjid">Building a Masjid</DropdownItem>
                  <DropdownItem href="/water">Water Well</DropdownItem>
                  <DropdownItem href="/dental">Dental Clinic</DropdownItem>
                  <DropdownItem href="/tubewell">Tubewell</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink href="/about/contact/">Contact</NavLink>
              </NavItem>
              <NavItem>
                {"live" === Cfg.stage ? (
                  <NavLink href="http://littlehope.org.uk/my-sadaqa/">
                    My Sadaqa
                  </NavLink>
                ) : (
                  <NavLink id="crowd-home" href="/">
                    My Sadaqa
                  </NavLink>
                )}
              </NavItem>
              <NavItem>
                <NavLink href="http://littlehope.org.uk/donate/">
                  Donate
                </NavLink>
              </NavItem>

              <NavItem>
                {"loggedin" === props.__auth.status ? (
                  <NavLink id="prof-home" tag={Link} to="/profile">
                    <i className="fa fa-user mr-1"> </i>
                    {" Profile"}
                  </NavLink>
                ) : (
                  <NavLink id="auth-reg" tag={Link} to="/auth/register">
                    <i className="fa fa-user mr-1"> </i>
                    {" Register"}
                  </NavLink>
                )}
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}; // IndexNavbar

/**
 */
// export default IndexNavbar;
/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(IndexNavbar);
