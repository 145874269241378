/**
 * @copyright KARD
 */

import React from 'react'

// import {navigate} from "@reach/router";

// import {
// 	Grid,
// } from '@material-ui/core'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

// import * as Cfg from '../../_cfg'

// import {Time, UI} from "../../api/Utils";

// import {
//     // Core,
//     DDB,
// } from '../../api/AWS'

// import Addr from '../../aui/addr'

/**
 */
const ListPack = ( props ) =>
{
    const [listPack, ListPack] = React.useState( props.pk )

    //
    return (
        <div style={{overflow: 'auto'}} >
            <div>
                {
                    listPack.map( (x, i) => (
                        <div key={i}>
                            <div
                                className="border border-primary rounded m-2 p-2"
                                style={{
                                    backgroundColor: props.pk && props.pk.tn === x.tn ? 'lightgreen' : null,
                                }}
                                onClick={ e => {
                                    e.preventDefault()
                                    e.stopPropagation()

                                    props.OnSelect( x )

                                    props.OnClose()
                                } }
                            >
                                <h3>{x.n}</h3>
                                {/* <hr/> */}
                                <h5>{'£'}{x.a} - {'rec' === x.p ? 'Recurring' : 'Once'}</h5>
                            </div>
                        </div>
                    ) )
                }
                <button
                    // key={i}
                    className="form-control"
                    style={{
                        margin: 5, padding: 5,
                        backgroundColor: props.pk && props.pk.tn === -1 ? '#28a745' : null,
                    }}
                    onClick={ e => {
                        e.preventDefault()
                        e.stopPropagation()

                        props.OnSelect( {tn: -1, n: 'Other', a: 0, p: 'one',} )
                        props.OnClose()

                    } }
                >
                    {
                        `Other: £`
                    }
                </button>
            </div>

        </div>
    )
}   // ListPack
/**
 */
const mapStateToProps = ( state ) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( ListPack )






