import React from "react";
import "../home/style.css";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
const items = [
  {
    src: require("assets/img/campaigns/water.png").default,
    creator: require("assets/img/m-avatar.png").default,
    title: "Tubewell",
    title2: "Gabe's memorial fund",
    description: "",
    lastdonatehr: "9",
    targetamount: "1000",
    receiveamount: "220",
  },
];
function WaterWell() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="section section-campaign">
          <Container>
            <Row className="">
              <Col lg="9" sm="6">
                {items.map((item) => {
                  return (
                    <div>
                      <CardBody className="pt-0 pl-0">
                        <CardTitle tag="h4" className="text-uppercase">
                          {item.title}
                        </CardTitle>
                        <img
                          alt="..."
                          className="responsive mb-2"
                          src={item.src}
                        ></img>
                        <CardText>
                          <p>Water is NOT life when it is undrinkable. </p>
                          <p>
                            People in Bangladesh walk for miles to reach the
                            nearest water source just to get water that
                            is dirty and diseased. They drink it knowing it
                            could kill them, but there is simply no other
                            choice.
                          </p>
                          <p>
                            More than 1.8 million people in Bangladesh lack
                            access to safe water sources and 36 million lack
                            improved sanitation.
                          </p>
                          <p>
                            On top of that, the COVID-19 pandemic made
                            it challenging for families in Bangladesh to access
                            safe water more than ever.
                          </p>
                          <p>
                            Bangladesh is extremely in need of water and
                            sanitation improvements RIGHT NOW!!
                          </p>
                           
                          <p>
                            Please donate to save lives by providing tubewell in
                            Bangladesh.
                          </p>
                          <p>Tubewell: £300</p>
                        </CardText>
                      </CardBody>
                    </div>
                  );
                })}
              </Col>
              <Col lg="3" sm="6">
                {items.map((item) => {
                  return (
                    <Card>
                      <img
                        alt="..."
                        className="responsive"
                        src={item.creator}
                      ></img>
                      <CardBody>
                        <CardTitle tag="h5">Created By: Jon Doe</CardTitle>
                        <CardText>{item.description}</CardText>
                        <p>Last donate {item.lastdonatehr} hr ago</p>
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: "70%" }}
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <CardTitle tag="h3" className="font-weight-bold">
                          {item.receiveamount}$ Raised{" "}
                          <span className="text-muted">
                            of {item.targetamount}$
                          </span>
                        </CardTitle>
                      </CardBody>
                    </Card>
                  );
                })}

                {items.map((item) => {
                  return (
                    <Card>
                      <CardBody>
                        <CardTitle tag="h5">Recent Donator</CardTitle>
                        <CardText>{item.description}</CardText>
                        <div>
                          <p className="font-weigh-bold text-green">
                            Sarwar Hossain $ 50{" "}
                          </p>
                          <p className="font-weigh-bold text-green">
                            Arif Hossain $ 50{" "}
                          </p>
                          <p className="font-weigh-bold text-green">
                            Ryan Hossain $ 50{" "}
                          </p>
                          <p className="font-weigh-bold text-green">
                            Asif Hossain $ 50{" "}
                          </p>
                          <p className="font-weigh-bold text-green">
                            Koushik Hossain $ 50{" "}
                          </p>
                        </div>
                      </CardBody>
                    </Card>
                  );
                })}
                <a href="/donation" class="btn btn-lg btn-block btn-green">
                  Donate Now
                </a>
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default WaterWell;
