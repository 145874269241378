/**
 * @copyright KARD
 */

import React from "react";
import "../home/style.css";

// reactstrap components
// import {
//   Button,
//   Modal,
//   ModalBody,
// } from "reactstrap";

import { connect } from "react-redux";
import * as actions from "rdx/actions";

/**
 */
const ListProj = ( props ) =>
{
    const [isBusy, IsBusy] = React.useState( false )
    const [list, List] = React.useState( props?.list||[] )
    const [lastKey, LastKey] = React.useState( null )
    // console.log("list Project")

    /**
     */
    return (
        <div style={{ overflow: "auto" }}>
            <div>
                {list.map((x, i) => (
                    <div key={i}>
                        <div
                            className="rounded mx-3 p-2 my-4"
                            style={{
                                border: "1px solid #DDE1E6",
                                backgroundColor:
                                    props.selected && props.selected.tn === x.tn ? "#EFF8FF" : "white",
                            }}
                            onClick={(e) => {
                                // e.preventDefault();
                                // e.stopPropagation();

                                props.OnSelect(x);

                                props.OnClose();
                            }}
                        >
                            <h3
                                className="text-left"
                                style={{ fontSize: 18, color: "#060c27" }}
                            >
                                {x.p.t}
                            </h3>

                            <h5 style={{ color: "#51586c", fontSize: "16" }}>{x.p.d}</h5>
                        </div>
                    </div>
                ))}
            </div>

            {/*<div>*/}
            {/*    {lastKey && (*/}
            {/*        <button*/}
            {/*            className="btn btn-info ml-3"*/}
            {/*            style={{*/}
            {/*                margin: 5,*/}
            {/*                padding: 5,*/}
            {/*            }}*/}
            {/*            onClick={() => Load(true).catch((err) => {})}*/}
            {/*        >*/}
            {/*            Show More*/}
            {/*        </button>*/}
            {/*    )}*/}
            {/*</div>*/}
        </div>
    )
}   // ListProj

/**
 */
export default ListProj




