/**
 *  @copyright KARD
 */

import React from 'react';

import Amplify from 'aws-amplify'

import {Provider} from 'react-redux'
import {createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {persistStore} from 'redux-persist'
import {PersistGate} from 'redux-persist/integration/react'
import reducers from './rdx/reducers'

// import cfg_core from './_cfg/_core'
import * as Cfg from './_cfg'

import AppNav from './router'

const rdx_store = createStore( reducers, applyMiddleware(thunk) )
const rdx_persistor = persistStore( rdx_store )

/**
 */
// Amplify.configure( {Auth: amplify_config.clinic} )

// console.log("auth_cfg[cfg_core.service][cfg_core.country][cfg_core.stage]",auth_cfg[cfg_core.service][cfg_core.country][cfg_core.stage])

// const auth_obj = ...

// Amplify.configure( auth_cfg[cfg_core.service][cfg_core.country][cfg_core.stage] )
Amplify.configure( Cfg.auth[Cfg.core.stage] )

/**
 */
class App extends React.PureComponent
{
    /**
     */
    render()
    {
        return (
            <Provider store={rdx_store}>
                <PersistGate loading={null} persistor={rdx_persistor}>
                    <AppNav />
                </PersistGate>
            </Provider>
        )
    }   // render
}   // App

/**
 */
export default App


